.thanks{
	background: #F9F9F9;
	padding-top: 4.3vh;
	padding-bottom: 4vh;
	position: relative;
	z-index: 2;
	@media (max-width: $max-sm) {
		padding-top: 1.27vh;
	}
	&__title{
		font-weight: 600;
		font-size: 1.49em;
		line-height: 130%;
		text-align: center;
		color: #5A5A5A;
		br{
			display: none;
		}
		@media (max-width: $max-sm) {
			font-size: 1.47em;
			line-height: 130%;
			br{
				display: block;
			}
			p{
				&:not(:last-child){
					padding-bottom: 0.4em;
				}
			}
		}
	}
	&__mail{
		padding-top: 3.3vh;
		@media (max-width: $max-sm) {
			padding-top: 3.6vh;
		}
		&-topic{
			font-size: 1.247em;
			font-weight: 500;
			line-height: 120%;
			text-align: center;
			color: #9B9B9B;
			padding-bottom: 2.2vh;
			@media (max-width: $max-sm) {
				font-size: 1.29em;
				padding-bottom: 3.2vh;
			}
		}
		&-mass{
			max-width: 33.2em;
			margin: 0 auto;
			@media (max-width: $max-sm) {
				margin-left: 0;
				max-width: 32.1em;
			}
		}
		&-set{
			margin-left: -1.56em;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			@media (max-width: $max-sm) {
				margin-left: -1.38em;
				justify-content: center;
			}
		}
		&-box{
			max-width: 25%;
			padding-left: 1.56em;
			padding-bottom: 1.3em;
			@media (max-width: $max-sm) {
				padding-left: 1.38em;
				padding-bottom: 0.7em;
				text-align: center;
				max-width: none;
				width: auto;
			}
			a{
				display: inline-flex;
				width: 100%;
				@include trs;
				&:hover{
					opacity: 0.5;
				}
			}
			img{
				max-width: 7.2em;
				max-height: 2.8em;
				width: 100%;
				object-fit: cover;
				@media (max-width: $max-sm) {
					max-width: 9.58em;
					max-height: 3.8em;
				}
			}
		}
	}
	&__price{
		padding-top: 3.2vh;
		&-topic{
			font-weight: 600;
			font-size: 1.27em;
			line-height: 120%;
			text-align: center;
			color: #5A5A5A;
			padding-bottom: 2.8vh;
			@media (max-width: $max-sm) {
				font-size: 1.1em;
				line-height: 140%;
				padding-bottom: 1.8vh;
			}
		}
		&-link{
			text-align: center;
			a{
				padding: 1em 1.5em 1.1em 1.5em;
				@media (max-width: $max-sm) {
					padding: 1.5em 2.4em 1.8em 2.4em;
				}
			}
		}
	}
	&__text{
		padding-top: 2.2vh;
		max-width: 32.8em;
		margin: 0 auto;
		font-size: 0.875em;
		line-height: 130%;
		text-align: center;
		color: #9B9B9B;
		@media (max-width: $max-sm) {
			padding-top: 1.6vh;
			font-size: 0.92em;
			line-height: 150%;
			padding-right: 0;
			max-width: 90%;
		}
	}
	&__segue{
		max-width: 50em;
		margin: 0 auto;
		padding-top: 4.8vh;
		@media (max-width: $max-sm) {
			padding-top: 3.1vh;
		}
		&-topic{
			font-weight: 500;
			font-size: 1.27em;
			line-height: 130%;
			text-align: center;
			color: #5A5A5A;
			@media (max-width: $max-sm) {
				font-size: 1.12em;
				line-height: 140%;
			}
		}
		&-link{
			padding-top: 2.7vh;
			text-align: center;
			@media (max-width: $max-sm) {
				padding-top: 1.7vh;
			}
			a{
				padding: 1em 2.7em 1.1em 2.7em;
				@media (max-width: $max-sm) {
					padding: 1.5em 3.6em 1.8em 3.6em;
				}
			}
		}
	}
}