.loader{
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(89.96deg, #F3A810 7.9%, #F9CA25 97.96%);
    z-index: 100;
}
.loader .loader__main{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14em;
    height: 20em;
    margin: 0;
    transform: translate(-50%,-50%);
    animation: upward infinite;
    animation-duration: 1.5s;
}
@media (max-width: 767px){
    .loader__main{
        width: 8em;
        height: 14em;
    }
}

.loader__img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}