.header{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	padding-top: 1.2em;
	padding-bottom: 1.2em;
	z-index: 10;
	@include trs;
	@media (max-width: $max-sm) {
		padding-top: 1.3em;
		padding-bottom: 1.3em;
	}
	&.scroll{
		position: fixed;
		background: #F9F9F9;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
	}
	&__wrapper{
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		@media (max-width: $max-sm) {
			justify-content: space-between;
		}
	}
	&__company{
		width: 33%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		@media (max-width: $max-sm) {
			width: auto;
		}
		&-logotype{
			display: flex;
			a{
				display: flex;
				align-items: flex-start;
				img{
					width: 10.27em;
					@media (max-width: $max-sm) {
						width: 11.17em;
					}
				}
			}
		}
		&-text{
			font-size: 0.748em;
			line-height: 1.4em;
			color: rgba(0, 0, 0, 0.5);
			padding-left: 2.6em;
			padding-top: 0.1em;
			@media (max-width: $max-sm) {
				display: none;
			}
		}
	}
	&__menu{
		width: 48%;
		padding-left: 0.95em;
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__contacts{
		width: 19%;
		text-align: right;
		padding-left: 1em;
		padding-right: 0.15em;
		padding-top: 0.1em;
		@media (max-width: $max-sm) {
			width: 100%;
			text-align: left;
			padding-left: 0;
			padding-right: 0;
			padding-top: 2.2em;
		}
		&-text{
			font-size: 0.558em;
			line-height: 1.3em;
			color: #5A5A5A;
			padding-bottom: 0.9em;
			@media (max-width: $max-sm) {
				font-size: 0.92em;
				line-height: 1.2em;
				padding-bottom: 0.6em;
			}
		}
		&-phone{
			a{
				text-decoration: none;
				font-weight: 500;
				font-size: 1.24em;
				line-height: 1.24em;
				color: #5A5A5A;
				@include trs;
				@media (max-width: $max-sm) {
					font-size: 2.01em;
					line-height: 1.2em;
				}
				&:hover{
					color: #F3A810;
				}
			}
		}
	}
	&__part{
		display: none;
		@media (max-width: $max-sm) {
			display: flex;
			padding-left: 1em;
		}
	}
	&__toggle{
		max-width: 3.7em;
		min-width: 3.7em;
		width: 100%;
		margin-left: auto;
		cursor: pointer;
		@include trs;
		span{
			display: block;
		    height: 0.2em;
		    width: 100%;
		    margin-bottom: 0.78em;
		    background-color: #000000;
		    margin-left: auto;
		    margin-right: 0.7em;
			&:last-child{
				margin-bottom: 0;
			}
		}
		&:hover{
			opacity: 0.5;
		}
	}
}

.drop{
	position: fixed;
	left: -100%;
	top: 0;
	max-width: 29.4em;
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	z-index: 20;
	padding: 1.3em 1.4em 2.3em 1.4em;
	overflow: auto;
	@include trs;
	&.open{
		left: 0;
	}
	&__head{
		padding-bottom: 3.8em;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	&__logotype{
		display: inline-flex;
		a{
			display: inline-flex;
			align-items: flex-start;
			img{
				width: 11em;
			}
		}
	}
	&__close{
		width: 1.85em;
		height: 1.85em;
		margin-left: 2em;
		display: inline-block;
		cursor: pointer;
		@include trs;
		&:hover{
			opacity: 0.5;
		}
	}
	&__bonds{
		padding-top: 0.8em;
		&-text{
			color: #5A5A5A;
			font-size: 0.92em;
			line-height: 1.2em;
			padding-bottom: 0.8em;
		}
		&-set{
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			justify-content: flex-start;
			a{
				min-width: 7.9em;
				text-align: center;
				display: inline-block;
				font-weight: 500;
				font-size: 1.1em;
				line-height: 1.55em;
				color: #FFFFFF;
				text-decoration: none;
				border-radius: 0.4em;
				padding: 0.18em 1.25em 0.21em 1.25em;
				margin-bottom: 0.7em;
				@include trs;
				&:hover{
					opacity: 0.5;
				}
			}
		}
	}
}

.menu{
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;
	display: flex;
	flex-wrap: wrap;
	&__item{
		padding-top: 0.2em;
	    padding-bottom: 0.2em;
    	padding-right: 1.06em;
    	@media (max-width: $max-sm) {
			width: 100%;
			padding-top: 0;
			padding-bottom: 1.75em;
			padding-right: 0;
		}
	}
	&__link{
		text-decoration: none;
		font-size: 1em;
		line-height: 1em;
		color: #5A5A5A;
		@include trs;
		&:hover{
			color: #F3A810;
		}
		@media (max-width: $max-sm) {
			font-size: 1.46em;
		}
	}
}