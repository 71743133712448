.advantages{
	padding-top: 6.28em;
	padding-bottom: 7.4em;
	background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #F9F9F9 100%);
	@media (max-width: $max-sm) {
		padding-top: 5.4em;
		padding-bottom: 5.55em;
	}
	&__title{
		text-align: center;
		@media (max-width: $max-sm) {
			max-width: 22em;
			margin: 0 auto;
		}
	}
	&__mass{
		padding-top: 0.6em;
		@media (max-width: $max-sm) {
			padding-top: 3em;
		}
	}
	&__set{
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-left: -2em;

	}
	&__box{
		padding-top: 4.18em;
		padding-left: 2em;
		width: 33.3%;
		@media (max-width: $max-sm) {
			width: 100%;
			padding-top: 2.2em;
		}
	}
	&__aloft{
		@media (max-width: $max-sm) {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}
	&__icon{
		pointer-events: none;
		display: block;
		height: 8.43em;
		@media (max-width: $max-sm) {
			height: 8.55em;
		}
	}
	&__name{
		padding-top: 1.1em;
		font-weight: 600;
		font-size: 1.38em;
		line-height: 1.34em;
		color: #5A5A5A;
		@media (max-width: $max-sm) {
			font-size: 1.4em;
			line-height: 1.4em;
			padding-top: 0;
			padding-left: 0.5em;
		}
	}
	&__text{
		padding-top: 0.6em;
		font-weight: 300;
		font-size: 1em;
		line-height: 1.61em;
		color: #454545;
		b{
			font-weight: 500;
		}
		@media (max-width: $max-sm) {
			br{
				display: none;
			}
			font-size: 1.1em;
			line-height: 1.51em;
			padding-top: 1.3em;
		}
	}
}