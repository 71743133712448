.records{
	padding-top: 6.2em;
	padding-bottom: 6.5em;
	background: #F9F9F9;
	@media (max-width: $max-sm) {
		padding-top: 5.55em;
		padding-bottom: 4.9em;
	}
	&__wrapper{
		position: relative;
	}
	&__leaf{
		&-1{
			display: none;
			position: absolute;
			top: 27.8em;
			right: -1.5em;
			width: 17.5em;
			pointer-events: none;
			@media (max-width: $max-sm) {
				display: block;
			}
		}
	}
	&__title{
		text-align: center;
	}
	&__dyad{
		padding-top: 3.6em;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		@media (max-width: $max-sm) {
			flex-wrap: wrap;
		}
	}
	&__left{
		width: 39.8%;
		@media (max-width: $max-sm) {
			width: 100%;
			order: 2;
			padding-top: 1.8em;
		}
	}
	&__list{
		counter-reset: list;
	}
	&__box{
		padding-top: 3.4em;
		padding-bottom: 0.9em;
		position: relative;
		@media (max-width: $max-sm) {
			padding-bottom: 0.6em;
		}
		&:before{
			counter-increment: list;
			content: "0" counter(list);
			position: absolute;
			left: 0.42em;
			top: 0;
			font-family: 'Roboto Slab';
			font-size: 4.4em;
			line-height: 1.135em;
			background: linear-gradient(180deg, #DDDBDB 0%, rgba(231, 231, 231, 0.2) 84.25%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			color: rgba(196, 196, 196, 0.72);
			@media (max-width: $max-sm) {
				font-size: 4.6em;
			}
		}
	}
	&__item{
		background: #EDEDED;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
		font-weight: 600;
		font-size: 1.492em;
		line-height: 1.25em;
		color: #F6B518;
		position: relative;
		z-index: 1;
		border-radius: 0.7em;
		padding: 1.25em 1.18em 1.25em 1.18em;
		@media (max-width: $max-sm) {
			padding: 1.65em 0.5em 1.8em 1.2em;
			font-size: 1.47em;
			line-height: 1.3em;
		}
		&:before{
			content: '';
			position: absolute;
			top: 0.38em;
			right: 0.38em;
			bottom: 0.38em;
			left: 0.38em;
			background-color: #ffffff;
			border-radius: 0.7em;
			z-index: -1;
		}
	}
	&__right{
		width: 50.5%;
		margin-right: 1.9em;
		padding-top: 0.9em;
		@media (max-width: $max-sm) {
			width: 100%;
			margin-right: 0;
			padding-top: 0;
			order: 1;
		}
	}
	&__figure{
		padding-bottom: 100%;
		width: 100%;
		border-radius: 50%;
		background: #F6B518;
		position: relative;
	}
	&__image{
		position: absolute;
		left: 50%;
		top: 47.8%;
		transform: translate(-50%, -50%);
		width: 107%;
		pointer-events: none;
	}
}