.making{
	padding-top: 6.15em;
	background: #F9F9F9;
	position: relative;
	overflow: hidden;
	@media (max-width: $max-sm) {
		padding-top: 5.59em;
	}
	&__title{
		text-align: center;
		position: relative;
		z-index: 3;
		@media (max-width: $max-sm) {
			display: none;
		}
		&-mob{
			margin: 0 -0.3em;
			display: none;
			font-weight: 700;
			font-size: 1.65em;
			line-height: 1.42em;
			color: #5A5A5A;
			text-align: center;
			@media (max-width: $max-sm) {
				display: block;
			}
		}
	}
	&__mass{
		padding-top: 3.8em;
		position: relative;
		padding-bottom: 7.3em;
		@media (max-width: $max-sm) {
			padding-top: 2.8em;
			padding-bottom: 5.3em;
		}
	}
	&__image{
		position: absolute;
		right: -10.9em;
		bottom: 0;
		width: 63em;
		pointer-events: none;
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__content{
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		z-index: 3;
		@media (max-width: $max-sm) {
			flex-wrap: wrap;
		}
	}
	&__vantage{
		width: 34%;
		@media (max-width: $max-sm) {
			width: 100%;
		}
		&-item{
			&:not(:last-child){
				padding-bottom: 1.96em;
				@media (max-width: $max-sm) {
					padding-bottom: 2.4em;
				}
			}
		}
		&-name{
			font-weight: 600;
			font-size: 1.365em;
			line-height: 1.37em;
			color: #F6B518;
			@media (max-width: $max-sm) {
				font-size: 1.47em;
				line-height: 1.52em
			}
		}
		&-text{
			padding-top: 0.8em;
			font-weight: 300;
			font-size: 1em;
			line-height: 1.62em;
			color: #454545;
			b{
				font-weight: 500;
			}
			@media (max-width: $max-sm) {
				padding-top: 0.7em;
				font-size: 1.1em;
				line-height: 1.49em;
			}
		}
	}
	&__edge{
		padding-left: 2.8em;
		width: 29.4%;
		@media (max-width: $max-sm) {
			padding-left: 0;
			width: 100%;
			padding-top: 3.7em;
		}
		&-item{
			background: #EDEDED;
			box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
			border-radius: 1em;
			text-align: center;
			padding: 1.5em 1em 2.1em 1em;
			position: relative;
			z-index: 1;
			@media (max-width: $max-sm) {
				border-radius: 1.47em;
				padding: 2.4em 1em 3.15em 1em;
			}
			&:before{
				content: '';
				position: absolute;
				top: 0.58em;
				right: 0.58em;
				bottom: 0.58em;
				left: 0.58em;
				background-color: #ffffff;
				border-radius: 1em;
				z-index: -1;
				@media (max-width: $max-sm) {
					top: 0.95em;
					right: 0.95em;
					bottom: 0.95em;
					left: 0.95em;
					border-radius: 1.47em;
				}
			}
			&:not(:last-child){
				margin-bottom: 3.26em;
				@media (max-width: $max-sm) {
					margin-bottom: 2.34em;
				}
			}
		}
		&-num{
			font-weight: 700;
			font-size: 2em;
			line-height: 140%;
			color: #F6B518;
			@media (max-width: $max-sm) {
				font-size: 2.95em;
				line-height: 140%;
			}
		}
		&-text{
			font-weight: 300;
			font-size: 1em;
			line-height: 1.22em;
			color: #454545;
			@media (max-width: $max-sm) {
				font-size: 1.47em;
				line-height: 1.25em;
			}
		}
	}
	&__ellipse{
		background: rgba(249, 249, 249, 0.6);
		border-radius: 50%;
		position: absolute;
		width: 85.125em;
		height: 43.1875em;
		z-index: 2;
		@media (max-width: $max-sm) {
			display: none;
		}
		&-1{
			left: 3.125em;
			bottom: -25.68em;
			filter: blur(6em);
		}
		&-2{
			left: 4.5625em;
			bottom: -35.2em;
			filter: blur(3em);
		}
		&-3{
			left: 2.4375em;
			bottom: -35.8em;
			filter: blur(3em);
		}
	}
	&__leaf{
		display: none;
		position: absolute;
		z-index: 4;
		pointer-events: none;
		@media (max-width: $max-sm) {
			display: block;
		}
		&-1{
			top: 3em;
			left: 0;
			width: 4.1em;
		}
		&-2{
			top: 19.5em;
			right: 0;
			width: 7.2em;
		}
		&-3{
			top: 42.5em;
			left: 0;
			width: 8.2em;
		}
		&-4{
			top: 63.5em;
			right: 0;
			width: 6.2em;
		}
		&-5{
			top: 74em;
			left: 1.8em;
			width: 9.4em;
		}
	}
}