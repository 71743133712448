::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eeeeee; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #A1A2A1; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #A1A2A1; 
}