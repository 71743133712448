.delivery{
	padding-top: 6.2em;
	padding-bottom: 8.4em;
	position: relative;
	@media (max-width: $max-sm) {
		padding-top: 5.6em;
		padding-bottom: 3.7em;
	}
	&__wrapper{
		padding-right: 31em;
		padding-left: 0.1em;
		position: relative;
		z-index: 2;
		@media (max-width: $max-sm) {
			padding-right: 0;
			padding-left: 0;
		}
	}
	&__circle{
		position: absolute;
		right: -37em;
		top: 0.3em;
		width: 62.03em;
		height: 62.03em;
		background: #F6B518;
		border-radius: 50%;
		z-index: -1;
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__title{
		@media (max-width: $max-sm) {
			display: none;
		}
		&-mob{
			display: none;
			@media (max-width: $max-sm) {
				display: block;
				text-align: center;
				font-weight: 700;
				font-size: 1.65em;
				line-height: 1.46em;
				text-align: center;
				color: #5A5A5A;
			}
		}
	}
	&__titlesub{
		padding-top: 1.4em;
		font-weight: 500;
		font-size: 1.37em;
		line-height: 1.4em;
		color: #9B9B9B;
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__car{
		position: absolute;
		bottom: -8.7em;
		left: 33.1em;
		width: 47em;
		pointer-events: none;
		@media (min-width: $min-xl) {
			width: 53em;
		}
		@media (max-width: $max-sm) {
			position: relative;
			left: -0.4em;
			bottom: 0;
			width: 36.5em;
			margin-top: 2.2em;;
		}
	}
	&__list{
		padding-top: 3.65em;
		padding-left: 0.7em;
		@media (max-width: $max-sm) {
			padding-top: 0;
			padding-left: 0;
			margin-top: -0.8em;
		}
	}
	&__row{
		max-width: 34em;
		padding-left: 3.35em;
		padding-top: 0.3em;
		margin-bottom: 2.5em;
		position: relative;
		@media (max-width: $max-sm) {
			padding-top: 0;
			padding-left: 3.95em;
			max-width: none;
			width: 100%;
			margin-bottom: 1.8em;
		}
		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 2.5em;
			height: 2.5em;
			background-image: url(../img/icon/check.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			@media (max-width: $max-sm) {
				width: 2.7em;
				height: 2.7em;
			}
		}
	}
	&__name{
		font-weight: 600;
		font-size: 1.37em;
		line-height: 1.37em;
		color: #454545;
		span{
			color: #F6B518;
		}
		@media (max-width: $max-sm) {
			display: none;
		}
		&-mob{
			display: none;
			@media (max-width: $max-sm) {
				display: block;
				font-weight: 500;
				font-size: 1.285em;
				line-height: 1.44em;
				color: #454545;
			}
		}
	}
	&__text{
		padding-top: 0.8em;
		font-weight: 300;
		font-size: 0.994em;
		line-height: 1.6em;
		color: #454545;
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__gift{
		position: absolute;
		top: 12.9em;
		right: 0;
		background: #FFFFFF;
		box-shadow: 0px 48.0288px 42.6923px rgba(0, 0, 0, 0.05);
		border-radius: 1em;
		width: 32.4em;
		padding: 3.3em 1.82em 1em 1.82em;
		@media (max-width: $max-sm) {
			display: none;
		}
		&-icon{
			position: absolute;
			left: -2.2em;
			top: -2.2em;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #FAC924;
			box-shadow: 0px 12.8077px 16.0096px rgba(250, 200, 34, 0.4);
			width: 5.3em;
			height: 5.3em;
			border-radius: 50%;
			img{
				width: 53%;
				height: 57%;
			}
		}
		&-title{
			font-weight: 300;
			font-size: 1.24em;
			line-height: 150%;
			text-align: center;
			color: #454545;
			b{
				font-weight: 600;
			}
		}
		&-dyad{
			padding-top: 0.4em;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
		}
		&-image{
			width: 47.5%;
			pointer-events: none;
		}
		&-content{
			width: 52.5%;
			padding-top: 2.2em;
			padding-left: 0.35em;
		}
		&-text{
			font-weight: 300;
			font-size: 1em;
			line-height: 150%;
			color: #454545;
		}
		&-note{
			padding-top: 2.2em;
			margin-left: -1em;
			font-weight: 300;
			font-size: 0.664em;
			line-height: 150%;
			color: #454545;
		}
	}
}