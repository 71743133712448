.segments{
	padding-top: 6.8em;
	padding-bottom: 7.4em;
	@media (max-width: $max-sm) {
		padding-top: 8em;
		padding-bottom: 5.5em;
	}
	&__title{
		text-align: center;
		@media (max-width: $max-sm) {
			display: none;
		}
		&-mob{
			margin: 0 -0.2em;
			display: none;
			font-weight: 700;
			font-size: 1.65em;
			line-height: 1.42em;
			color: #5A5A5A;
			text-align: center;
			@media (max-width: $max-sm) {
				display: block;
			}
		}
	}
	&__mass{
		padding-top: 3.4em;
		@media (max-width: $max-sm) {
			padding-top: 1.5em;
		}
	}
	&__set{
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-left: -1.88em;
		counter-reset: list;
	}
	&__box{
		padding-left: 1.88em;
		padding-top: 1.88em;
		width: 25%;
		@media (min-width: $min-sm) {
			&:nth-child(8n+1), &:nth-child(8n+3), &:nth-child(8n+6), &:nth-child(8n+8){
				.segments__item{
					background: #F6B518;
					color: #ffffff;
					&:after{
						border: 0.05em solid #ffffff;
					}
					&:before{
						background: linear-gradient(180.68deg, #FFECBF 0.59%, rgba(255, 236, 191, 0) 86.9%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						color: #FFECBF;
						opacity: 0.4;
					}
				}
			}
		}
		@media (max-width: $max-sm) {
			width: 100%;
			padding-top: 2.3em;
			&:nth-child(odd){
				.segments__item{
					background: #F6B518;
					color: #ffffff;
					&:after{
						border: 0.05em solid #ffffff;
					}
					&:before{
						background: linear-gradient(180.68deg, #FFECBF 0.59%, rgba(255, 236, 191, 0) 86.9%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						color: #FFECBF;
						opacity: 0.4;
					}
				}
			}
		}
	}
	&__item{
		background: #FFF0C2;
		border-radius: 0.6em;
		min-height: 10.86em;
		box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
		font-weight: 600;
		font-size: 1.118em;
		line-height: 1.44em;
		text-align: center;
		color: #9E9E9E;
		padding: 4.7em 1.1em 1.1em 1.1em;
		position: relative;
		z-index: 1;
		@media (max-width: $max-sm) {
			font-size: 1.64em;
			line-height: 1.5em;
			min-height: 9.19em;
		}
		&:after{
			content: '';
			position: absolute;
			top: 0.56em;
			right: 0.56em;
			bottom: 0.56em;
			left: 0.56em;
			border-radius: 0.6em;
			border: 0.05em solid #CCCCCC;
			z-index: -1;
		}
		&:before{
			counter-increment: list;
			content: "0" counter(list);
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 0.5em;
			font-family: 'Roboto Slab';
			font-size: 3.892em;
			line-height: 1.143em;
			background: linear-gradient(180.55deg, #DDDDDD 0.47%, rgba(181, 181, 181, 0) 82.09%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			color: #DDDDDD;
			z-index: -1;
			opacity: 0.3;
			@media (max-width: $max-sm) {
				top: 0.2em;
			}
		}
	}
}