.major{
	position: relative;
	padding-bottom: 6.9em;
	@media (max-width: $max-sm) {
		padding-bottom: 3em;
	}
	&__wrapper{
		position: relative;
		z-index: 3;
		@media (max-width: $max-sm) {
			padding-top: 1.8em;
		}
	}
	&__nut{
		&-1{
			position: absolute;
			right: 16.7em;
			top: 2.6em;
			width: 5em;
			z-index: -1;
			@media (max-width: $max-sm) {
				right: 20.7em;
				top: 16.3em;
				width: 4em;
			}
		}
		&-2{
			position: absolute;
			right: -6.5em;
			top: 19.1em;
			width: 5em;
			z-index: -1;
			@media (max-width: $max-sm) {
				right: -1.5em;
				top: 30em;
				width: 4em;
			}
		}
		&-3{
			position: absolute;
			right: 18.2em;
			top: 18.2em;
			width: 5.8em;
			z-index: -1;
			@media (max-width: $max-sm) {
				right: 22.8em;
				top: 32.2em;
				width: 4.8em;
			}
		}
	}
	&__leaf{
		&-1{
			position: absolute;
			right: 16em;
			top: 2.5em;
			width: 9.4em;
			z-index: -1;
			@media (max-width: $max-sm) {
				right: 20em;
				top: 16em;
				width: 8em;
			}
		}
		&-2{
			position: absolute;
			right: 21.7em;
			top: 0;
			width: 12em;
			z-index: -1;
			@media (max-width: $max-sm) {
				display: none;
			}
		}
		&-3{
			position: absolute;
			right: -8em;
			top: 10.8em;
			width: 12em;
			z-index: -1;
			@media (max-width: $max-sm) {
				right: -2.8em;
				top: 22em;
				width: 10em;
			}
		}
		&-4{
			position: absolute;
			right: -10.6em;
			top: 18em;
			width: 13.4em;
			z-index: -1;
			@media (max-width: $max-sm) {
				right: -5.1em;
				top: 28.7em;
				width: 11.4em;
			}
		}
		&-5{
			position: absolute;
			right: 11.7em;
			top: 15.5em;
			width: 11.5em;
			z-index: -1;
			@media (max-width: $max-sm) {
				right: 17em;
				top: 29.8em;
				width: 10em;
			}
		}
		&-6{
			position: absolute;
			left: -21.3em;
			bottom: -7em;
			width: 37em;
			z-index: -1;
			@media (max-width: $max-sm) {
				display: none;
			}
		}
	}
	&__content{
		padding-top: 4em;
		padding-right: 25em;
		position: relative;
		z-index: 2;
		@media (max-width: $max-sm) {
			text-align: center;
			padding-top: 0;
			padding-right: 0;
		}
	}
	&__title{
		font-size: 2.18em;
		line-height: 1.42em;
		color: #5A5A5A;
		@media (max-width: $max-sm) {
			display: none;
		}
		&-mob{
			display: none;
			@media (max-width: $max-sm) {
				display: block;
				font-weight: 700;
				font-size: 2.75em;
				line-height: 1.35em;
				color: #5A5A5A;
			}
		}
	}
	&__text{
		font-weight: 500;
		font-size: 1.245em;
		line-height: 120.02%;
		color: #787878;
		padding-top: 1.1em;
		@media (max-width: $max-sm) {
			display: none;
			
		}
		&-mob{
			display: none;
			@media (max-width: $max-sm) {
				padding-top: 0.56em;
				display: block;
				font-weight: 500;
				font-size: 1.47em;
				line-height: 144.52%;
				color: #787878;
			}
		}
	}
	&__figure{
		position: absolute;
		right: -2.5em;
		top: 3em;
		width: 22em;
		height: 22em;
		background: #FFCE10;
		border-radius: 50%;
		@media (max-width: $max-sm) {
			position: relative;
			right: 0;
			top: 0;
			width: 21em;
			height: 21em;
			margin: 1.6em auto 0 auto;
		}
		&-image{
			position: absolute;
			left: 55%;
			top: 51%;
			transform: translate(-50%, -50%);
			width: 150%;
			height: 150%;
			object-fit: contain;
			pointer-events: none;
			@media (max-width: $max-sm) {
				width: 140%;
				height: 140%;
			}
		}
	}
	&__knob{
		padding-top: 2.5em;
		@media (max-width: $max-sm) {
			padding-top: 2.2em;
		}
		a{
			padding: 0.7em 1.4em 0.7em 1.4em;
			@media (max-width: $max-sm) {
				width: 100%;
				padding: 1.1em 0.9em 1.1em 0.8em;
			}
		}
		&-note{
			padding-top: 1.2em;
			padding-left: 0.2em;
			font-size: 1em;
			line-height: 1.4em;
			color: #9B9B9B;
			@media (max-width: $max-sm) {
				padding-top: 0.8em;
				font-size: 1.1em;
				line-height: 1.22em;
				padding-left: 0;
				text-align: center;
			}
		}
	}
	&__benefits{
		padding-top: 2.8em;
		padding-right: 0.5em;
		@media (max-width: $max-sm) {
			padding-top: 6.5em;
			padding-right: 0;
		}
	}
}

.benefits{
	&__set{
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-left: -2em;
	}
	&__box{
		padding-left: 2em;
		width: 33.3%;
		@media (max-width: $max-sm) {
			width: 100%;
			padding-bottom: 2.4em;
		}
	}
	&__item{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		height: 100%;
		background: #FBFBFB;
		box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
		border-radius: 1.7em;
		z-index: 1;
		padding: 1.24em 8em 1.2em 2.55em;
		@media (max-width: $max-sm) {
			overflow: hidden;
			min-height: 8em;
			padding: 1.6em 6em 1.6em 2.25em;
		}
	}
	&__icon{
		position: absolute;
		right: 1.3em;
		top: 50%;
		transform: translateY(-50%);
		height: 5.5em;
		max-width: 5.5em;
		z-index: -1;
		pointer-events: none;
		@media (max-width: $max-sm) {
			right: -0.7em;
			height: 6em;
			max-width: 6em;
		}
	}
	&__name{
		font-family: 'Inter';
		font-weight: 400;
		font-size: 1em;
		line-height: 1.4em;
		color: #454545;
		@media (max-width: $max-sm) {
			font-size: 1.2em;
			line-height: 1.4em;
		}
	}
}