.own{
	padding-top: 6.2em;
	padding-bottom: 7.2em;
	@media (max-width: $max-sm) {
		padding-top: 5.6em;
		padding-bottom: 5.65em;
	}
	&__title{
		text-align: center;
	}
	&__titlesub{
		text-align: center;
		font-weight: 500;
		font-size: 1.28em;
		line-height: 2.16em;
		color: #9B9B9B;
		display: none;
		@media (max-width: $max-sm) {
			display: block;
			padding-top: 0.7em;
		}
	}
	&__dyad{
		padding-top: 2.8em;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		@media (max-width: $max-sm) {
			padding-top: 2.4em;
			flex-wrap: wrap;
		}
	}
	&__left{
		width: 51.75%;
		@media (max-width: $max-sm) {
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: center;
		}
	}
	&__figure{
		position: relative;
		width: 36.4em;
		height: 36.4em;
		margin-left: -0.7em;
		@media (max-width: $max-sm) {
			width: 34.5em;
			height: 34.5em;
			margin: 0 -6em;
		}
	}
	&__circle{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		border: 0.157em solid #F6B518;
		border-radius: 50%;
		&-1{
			width: 100%;
			height: 100%;
			opacity: 0.15;
		}
		&-2{
			width: 86%;
			height: 86%;
			opacity: 0.3;
		}
		&-3{
			width: 75%;
			height: 75%;
			opacity: 0.5;
		}
		&-4{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 22.65em;
			height: 22.65em;
			background: #F6B518;
			border-radius: 50%;
			@media (max-width: $max-sm) {
				width: 21.65em;
				height: 21.65em;
			}
		}
	}
	&__leaf{
		&-1{
			pointer-events: none;
			position: absolute;
			top: 2.6em;
			left: -8.7em;
			width: 26.2em;
			@media (max-width: $max-sm) {
				transform: rotate(-27.9deg);
				top: 13.4em;
				left: 3.4em;
				width: 20.2em;
			}
		}
		&-2{
			pointer-events: none;
			position: absolute;
			top: 1em;
			right: -0.8em;
			z-index: 2;
			width: 12em;
			@media (max-width: $max-sm) {
				display: none;
			}
		}
		&-3{
			display: none;
			position: absolute;
			top: -5em;
			right: 2.5em;
			width: 23em;
			@media (max-width: $max-sm) {
				display: block;
			}
		}
	}
	&__image{
		position: absolute;
		top: 48%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 15.43em;
		pointer-events: none;
		@media (max-width: $max-sm) {
			width: 14.6em;
		}
	}
	&__right{
		width: 48.25%;
		padding-top: 1.05em;
		padding-right: 5em;
		@media (max-width: $max-sm) {
			width: 100%;
			padding-right: 0;
			padding-top: 2.05em;
		}
	}
	&__topic{
		font-weight: 600;
		font-size: 1.37em;
		line-height: 1.37em;
		color: #F6B518;
		padding-left: 2.38em;
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__list{
		padding-top: 1.3em;
		&-item{
			position: relative;
			font-weight: 300;
			font-size: 1em;
			line-height: 1.617em;
			color: #454545;
			padding-left: 3.2em;
			@media (max-width: $max-sm) {
				font-size: 1.1em;
				line-height: 1.51em;
				padding-left: 3.5em;
				br{
					display: none;
				}
			}
			b{
				font-weight: 500;
			}
			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 1.75em;
				height: 1.75em;
				background-image: url(../img/icon/check.svg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				@media (max-width: $max-sm) {
					width: 2.36em;
					height: 2.36em;
				}
			}
			&:not(:last-child){
				margin-bottom: 1em;
				@media (max-width: $max-sm) {
					margin-bottom: 2em;
				}
			}
		}
	}
	&__point{
		position: absolute;
		&-1{
			left: 12.7em;
			top: 7.7em;
			@media (max-width: $max-sm) {
				left: 12em;
				top: 7.5em;
			}
			.own__point-content{
				right: -3.9em;
				top: 1.35em;
				width: 15.67em;
				padding: 1.25em 1em 1.4em 1.1em;
				@media (max-width: $max-sm) {
					right: -7.32em;
					top: auto;
					bottom: 2em;
					padding: 1.15em 1em 1.35em 1.3em;
					width: 16.4em;
				}
			}
		}
		&-2{
			left: 20.6em;
			top: 12.3em;
			@media (max-width: $max-sm) {
				left: 19.4em;
				top: 11.8em;
			}
			.own__point-content{
				left: 0.25em;
				top: 1.55em;
				width: 14em;
				padding: 1.25em 1em 1.4em 1.2em;
				@media (max-width: $max-sm) {
					left: -3.22em;
					top: 1.9em;
					width: 14.7em;
					padding: 1.15em 1em 1.35em 1.3em;
					padding: 1.15em 1em 1.35em 1.3em;
				}
			}
		}
		&-3{
			left: 12.7em;
			top: 18em;
			@media (max-width: $max-sm) {
				left: 12em;
				top: 17.2em;
			}
			.own__point-content{
				right: -2.9em;
				top: 1.6em;
				width: 14em;
				padding: 1.25em 1em 1.4em 1.1em;
				@media (max-width: $max-sm) {
					left: auto;
					right: -3.82em;
					top: 2.15em;
					padding: 1.15em 1em 1.35em 1.3em;
					width: 14.8em;
				}
			}
		}
		&-4{
			left: 20.6em;
			top: 22.7em;
			@media (max-width: $max-sm) {
				left: 19.4em;
				top: 21.6em;
			}
			.own__point-content{
				left: 0.25em;
				top: 1.55em;
				width: 14em;
				padding: 1.25em 1em 1.4em 1.2em;
				@media (max-width: $max-sm) {
					left: auto;
					right: -2.4em;
					top: 1.78em;
					width: 14.7em;
					padding: 1.15em 1em 1.35em 1.3em;
				}
			}
		}
		&-btn{
			width: 2.2em;
			height: 2.2em;
			background: #FFCF5B;
			box-shadow: 0px 12px 15px rgba(250, 200, 34, 0.4);
			border-radius: 50%;
			cursor: pointer;
			position: relative;
			z-index: 2;
			@media (max-width: $max-sm) {
				width: 2.78em;
				height: 2.78em;
			}
			&:after{
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				height: 53%;
				width: 0.07em;
				background-color: #ffffff;
			}
			&:before{
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 53%;
				height: 0.07em;
				background-color: #ffffff;
			}
			&:hover{
				+ .own__point-content{
					opacity: 1;
				}
			}
		}
		&-content{
			position: absolute;
			opacity: 0;
			pointer-events: none;
			background: rgba(255, 255, 255, 0.8);
			box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
			border-radius: 1em;
			@include trs;
		}
		&-name{
			font-weight: 600;
			font-size: 1em;
			line-height: 1.85em;
			color: #454545;
			padding-bottom: 0.15em;
			@media (max-width: $max-sm) {
				font-size: 1.1em;
				line-height: 1.65em;
			}
		}
		&-text{
			font-weight: 300;
			font-size: 0.88em;
			line-height: 1.44em;
			color: #454545;
			@media (max-width: $max-sm) {
				font-size: 0.93em;
				line-height: 1.26em;
			}
		}
	}
	&__btn{
		padding-top: 2.6em;
		text-align: center;
		a{
			padding: 1.1em 1.7em 1em 1.7em;
		}
		@media (max-width: $max-sm) {
			display: none;
		}
		&-mob{
			text-align: center;
			padding-top: 3.2em;
			padding-left: 0.7em;
			display: none;
			@media (max-width: $max-sm) {
				display: block;
			}
			a{
				padding: 1.6em 2.7em 1.7em 2.7em;
			}
		}
	}
}