.proof{
	padding-top: 6.3em;
	padding-bottom: 4.2em;
	@media (max-width: $max-sm) {
		padding-top: 6.6em;
		padding-bottom: 2.4em;
	}
	&__title{
		text-align: center;
		max-width: 35em;
		margin: 0 auto;
	}
	&__mass{
		padding-top: 3.2em;
		padding-left: 2.4em;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		@media (max-width: $max-sm) {
			padding-left: 0;
			padding-top: 3.5em;
		}
	}
	&__left{
		width: 26%;
		@media (max-width: $max-sm) {
			order: 2;
			width: 100%;
		}
	}
	&__right{
		width: 30.2%;
		@media (max-width: $max-sm) {
			order: 3;
			width: 100%;
		}
	}
	&__center{
		width: 43.8%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		padding-bottom: 2.6em;
		@media (max-width: $max-sm) {
			align-items: flex-start;
			order: 1;
			width: 100%;
			padding-bottom: 2.3em;
		}
	}
	&__item{
		margin-bottom: 1.3em;
		@media (max-width: $max-sm) {
			margin-bottom: 3.2em;
		}
		&-1{
			margin-bottom: 2.9em;
			@media (max-width: $max-sm) {
				margin-bottom: 3.2em;
			}
		}
		&-2{
			margin-bottom: 2.9em;
			@media (max-width: $max-sm) {
				margin-bottom: 3.2em;
			}
		}
	}
	&__num{
		font-family: 'Roboto Slab';
		font-size: 4.3em;
		line-height: 0.81em;
		background: linear-gradient(180deg, rgba(196, 196, 196, 0.72) 0%, rgba(196, 196, 196, 0.25) 84.25%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		color: rgba(196, 196, 196, 0.72);
		@media (max-width: $max-sm) {
			font-size: 4.6em;
			line-height: 0.9em;
		}
	}
	&__name{
		font-weight: 600;
		font-size: 1.37em;
		line-height: 1.35em;
		color: #F6B518;
		@media (max-width: $max-sm) {
			padding-top: 0.5em;
			font-size: 1.47em;
			line-height: 1.52em;
		}
	}
	&__text{
		padding-top: 0.6em;
		font-weight: 300;
		font-size: 1em;
		line-height: 1.61em;
		color: #454545;
		b{
			font-weight: 500;
		}
		@media (max-width: $max-sm) {
			font-size: 1.1em;
			line-height: 1.5em;;
			br{
				display: none;
			}
		}
	}
	&__figure{
		position: relative;
		width: 30.02em;
		height: 30.02em;
		margin-left: -1.8em;
		@media (max-width: $max-sm) {
			width: 27.3em;
			height: 27.3em;
			margin-left: -0.2em;
			margin-right: -0.6em;
		}
	}
	&__leaf{
		&-1{
			position: absolute;
			bottom: -4em;
			left: -6em;
			width: 45.5em;
			pointer-events: none;
			@media (max-width: $max-sm) {
				bottom: -3.7em;
				left: -5.2em;
				width: 41.5em;
			}
		}
		&-2{
			position: absolute;
			bottom: 8.7em;
			left: -4.2em;
			width: 31.5em;
			pointer-events: none;
			@media (max-width: $max-sm) {
				bottom: 7.7em;
				left: -3.7em;
				width: 29.5em;
			}
		}
		&-3{
			position: absolute;
			bottom: 1.6em;
			left: -2.8em;
			width: 11.6em;
			pointer-events: none;
			@media (max-width: $max-sm) {
				bottom: 1.4em;
				left: -2.4em;
				width: 10.6em;
			}
		}
		&-4{
			position: absolute;
			bottom: 3.8em;
			right: -3.5em;
			width: 17.2em;
			pointer-events: none;
			@media (max-width: $max-sm) {
				bottom: 3.8em;
				right: -3.2em;
				width: 15.2em;
			}
		}
		&-5{
			position: absolute;
			bottom: 6.1em;
			right: -1.9em;
			width: 17em;
			pointer-events: none;
			@media (max-width: $max-sm) {
				bottom: 6.1em;
				right: -1.2em;
				width: 14em;
			}
		}
	}
	&__circle{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		border: 0.157em solid #F6B518;
		border-radius: 50%;
		&-1{
			width: 100%;
			height: 100%;
			opacity: 0.15;
		}
		&-2{
			width: 86%;
			height: 86%;
			opacity: 0.3;
		}
		&-3{
			width: 75%;
			height: 75%;
			opacity: 0.5;
		}
		&-4{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 18.65em;
			height: 18.65em;
			background: #F6B518;
			border-radius: 50%;
			@media (max-width: $max-sm) {
				width: 17.02em;
				height: 17.02em;
			}
		}
	}
	&__image{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-49.5%, -48.5%);
		width: 30em;
		pointer-events: none;
		@media (max-width: $max-sm) {
			width: 27em;
		}
	}
}