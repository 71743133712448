.proposal{
	background: #F9F9F9;
	padding-top: 6.2em;
	padding-bottom: 2.5em;
	@media (max-width: $max-sm) {
		padding-top: 5.6em;
		padding-bottom: 5.6em;
	}
	&__wrapper{
		position: relative;
		z-index: 1;
	}
	&__leaf{
		&-1{
			position: absolute;
			bottom: -2.5em;
			left: -6.1em;
			width: 56.8em;
			pointer-events: none;
			@media (max-width: $max-sm) {
				display: none;
			}
		}
		&-2{
			position: absolute;
			left: -1.5em;
			top: 0.2em;
			width: 10em;
			display: none;
			pointer-events: none;
			@media (max-width: $max-sm) {
				display: block;
			}
		}
		&-3{
			position: absolute;
			right: -1.5em;
			bottom: -5.5em;
			display: none;
			pointer-events: none;
			width: 10em;
			@media (max-width: $max-sm) {
				display: block;
			}
		}
	}
	&__title{
		text-align: center;
	}
	&__text{
		padding-top: 1.3em;
		text-align: center;
		font-weight: 500;
		font-size: 1.373em;
		line-height: 1.67em;
		color: rgba(54, 54, 54, 0.5);
		span{
			color: #F6B518;
		}
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__dyad{
		padding-top: 1.5em;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		@media (max-width: $max-sm) {
			padding-top: 2.75em;
		}
	}
	&__left{
		width: 52.2%;
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__figure{
		width: 100%;
		position: relative;
	}
	&__image{
		width: 100%;
		pointer-events: none;
	}
	&__discount{
		position: absolute;
		right: 0.1em;
		top: 3.2em;
		width: 10.9em;
		height: 10.9em;
		object-fit: contain;
		pointer-events: none;
	}
	&__right{
		width: 44.8%;
		padding-top: 3.2em;
		padding-right: 0.3em;
		@media (max-width: $max-sm) {
			width: 100%;
			padding-right: 0;
			padding-top: 0;
		}
	}
	&__form{
		background: #EDEDED;
		box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.05);
		border-radius: 1em;
		padding: 3.6em 4.05em 3.3em 4.05em;
		position: relative;
		z-index: 1;
		@media (max-width: $max-sm) {
			border-radius: 0.8em;
			padding: 3.5em 1.5em 3em 1.5em;
		}
		&:before{
			content: '';
			position: absolute;
			top: 0.7em;
			right: 0.7em;
			bottom: 0.7em;
			left: 0.7em;
			background-color: #ffffff;
			border-radius: 1em;
			z-index: -1;
			@media (max-width: $max-sm) {
				top: 0.6em;
				right: 0.6em;
				bottom: 0.6em;
				left: 0.6em;
				border-radius: 0.8em;
			}
		}
	}
	&__topic{
		font-weight: 600;
		font-size: 1.5em;
		line-height: 1.46em;
		text-align: center;
		text-transform: uppercase;
		color: #5A5A5A;
		@media (max-width: $max-sm) {
			display: none;
		}
		&-mob{
			display: none;
			font-weight: 500;
			font-size: 1.1em;
			line-height: 1.7em;
			text-align: center;
			text-transform: uppercase;
			color: #5A5A5A;
			b{
				color: #F6B518;
			}
			@media (max-width: $max-sm) {
				display: block;
			}
		}
	}
	&__set{
		padding-top: 2em;
		max-width: 83.6%;
		margin: 0 auto;
		@media (max-width: $max-sm) {
			padding-top: 1.65em;
			max-width: 87.6%;
		}
	}
	&__row{
		padding-bottom: 0.77em;
		@media (max-width: $max-sm) {
			padding-bottom: 0.67em;
		}
	}
	&__icon{
		position: absolute;
		left: 1.2em;
		top: 50%;
		transform: translateY(-50%);
		width: 2.06em;
		height: 2.06em;
		border-radius: 50%;
		pointer-events: none;
		@media (max-width: $max-sm) {
			width: 1.74em;
			height: 1.74em;
			left: 1em;
		}
	}
	&__field{
		position: relative;
		input{
			display: block;
			width: 100%;
			background: #F9F9F9;
			border-radius: 0.5em;
			font-family: 'Montserrat';
			font-weight: 500;
			font-size: 0.87em;
			line-height: 1.43em;
			color: #5A5A5A;
			border: none;
			padding: 1.9em 1em 1.9em 4.8em;
			@include trs;
			&::placeholder{
				color: #AFAFAF;
			}
			&.invalid{
				box-shadow: inset 0px 2px 6px rgba(255, 0, 0, 0.4);
			}
			@media (max-width: $max-sm) {
				font-size: 0.745em;
				line-height: 1.3em;
				padding: 1.98em 1em 1.9em 4.7em;
			}
		}
	}
	&__note{
		padding-top: 0.95em;
		display: block;
		width: 100%;
		font-weight: 400;
		font-size: 0.87em;
		line-height: 1.43em;
		text-align: center;
		color: #A0A0A0;
		@media (max-width: $max-sm) {
			font-size: 0.746em;
			line-height: 1.32em;
			padding-top: 1.1em;
		}
	}
	&__btn{
		padding-top: 1.4em;
		text-align: center;
		@media (max-width: $max-sm) {
			padding-top: 1.2em;
		}
		button{
			padding: 1.08em 1.5em 1em 1.5em;
			@media (max-width: $max-sm) {
				padding: 0.9em 1.2em 1em 1.2em;
			}
		}
	}
	&__consent{
		padding-top: 1.35em;
		font-weight: 500;
		font-size: 0.75em;
		line-height: 1.25em;
		text-align: center;
		color: #C6C2C2;
		@media (max-width: $max-sm) {
			font-size: 0.82em;
			line-height: 1.45em;
			padding-top: 1em;
		}
		a{
			font-weight: 600;
			color: #C6C2C2;
			@include trs;
			&:hover{
				opacity: 0.5;
			}
		}
	}
}