input{
	&:focus::-webkit-input-placeholder{ 
		opacity: 0; 
		transition: opacity 0.3s ease;
	}
	&:focus:-moz-placeholder{ 
		opacity: 0; 
		transition: opacity 0.3s ease; 
	} 
	&:focus::-moz-placeholder{ 
		opacity: 0; 
		transition: opacity 0.3s ease; 
	}
	&:focus:-ms-input-placeholder{ 
		opacity: 0; 
		transition: opacity 0.3s ease; 
	}
	&:focus{
		outline: none;
	}
}

.button{
	text-decoration: none;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	font-family: 'Inter';
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: linear-gradient(89.96deg, #F3A810 7.9%, #F9CA25 97.96%);
	box-shadow: 0px 12px 15px rgba(250, 200, 34, 0.4);
	border-radius: 0.6em;
	color: #fefefe;
	font-size: 1.24em;
	text-transform: uppercase;
	border: none;
	font-weight: 700;
	@include trs;
	@media (max-width: $max-sm) {
		font-size: 1.1em;
		border-radius: 0.8em;
	}
	&__icon{
		min-width: 1.7em;
		max-width: 1.7em;
		height: 1.9em;
		object-fit: contain;
		margin-right: 0.7em;
		pointer-events: none;
		@media (max-width: $max-sm) {
			min-width: 2.02em;
			max-width: 2.02em;
			height: 2.28em;
			margin-right: 0.4em;
		}
	}
	&:hover{
		color: #fefefe;
		box-shadow: none;
	}
	&:focus{
		outline: none;
	}
}
.button:before{
	content: '';
	display: block;
	width: 6em;
	height: 50em;
	margin-left: 8em;
	background: rgba(255,255,255,.4);
	-webkit-box-shadow: 0 0 3em rgba(255,255,255,.4);
	box-shadow: 0 0 3em rgba(255,255,255,.4);
	position: absolute;
	left: -3em;
	top: -25em;
	z-index: 1;
	transform: rotate(45deg);
	@include trs;
	animation-name: shine;
	animation-duration: 4s;
	animation-delay: .3s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}
@-webkit-keyframes shine {
	0% {
		left: -2em;
		margin-left: 0
	}
	30% {
		left: 110%;
		margin-left: 3em
	}
	100% {
		left: 110%;
		margin-left: 3em
	}
}
@keyframes shine {
	0% {
		width: 2em;
		left: -2em;
		margin-left: 0
	}
	30% {
		left: 110%;
		width: 2em;
		margin-left: 3em
	}
	100% {
		width: 2em;
		left: 110%;
		margin-left: 3em
	}
}

.form{
	&__couple{
		display: flex;
		flex-wrap: wrap;
		margin-left: -1.9em;
	}
	&__row{
		padding-left: 1.9em;
		width: 50%;
		padding-bottom: 1.9em;
		@media (max-width: $max-sm) {
			width: 100%;
			padding-bottom: 2em;
		}
	}
	&__field{
		width: 100%;
		border-radius: 2em;
		border: 1px solid #ff771f;
		color: #000000;
		font-family: 'Roboto';
		font-size: 0.88em;
		font-weight: 400;
		text-align: left;
		letter-spacing: 0.01em;
	    padding: 1.43em 1.6em;
	    min-height: 3.275em;
		&::placeholder{
			color: rgba(0, 0, 0, 0.51);
		}
		&.invalid{
			border: 1px solid red;
			color:red;
			&::placeholder{
				color: red;
			}
		}
		@media (max-width: $max-sm) {
			font-size: 0.9em;
			padding: 1.6em 1.65em;
		}
	}
	&__knob{
		position: relative;
		width: 100%;
		&-img{
			pointer-events: none;
			position: absolute;
		    left: 1.5em;
    		top: -1em;
    		width: 5.91em;
    		height: 6.41em;
			z-index: 2;
			object-fit: contain;
		}
	}
	&__btn{
		justify-content: center;
		font-weight: 400;
		padding: 0.9em 0.9em 0.9em 2em;
		min-height: 3.275em;
		border-radius: 2em;
	}
	&__consent{
		padding-top: 1em;
		color: #000000;
	    font-size: 0.765em;
	    font-weight: 500;
	    text-align: left;
	    letter-spacing: 0.01em;
	    &-link{
	    	color: #000be1;
	    	text-decoration: none;
	    	@include trs;
			&:hover{
				opacity: 0.5;
			}
	    }
	    @media (max-width: $max-sm) {
			font-size: 0.8em;
			max-width: 20.8em;
			margin: 0 auto;
			text-align: center;
		}
	}
}