.catalog{
	background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #F9F9F9 100%);
	padding-top: 6.2em;
	padding-bottom: 7.3em;
	@media (max-width: $max-sm) {
		padding-top: 5.6em;
		padding-bottom: 5.5em;
	}
	&__title{
		text-align: center;
		@media (max-width: $max-sm) {
			br{
				display: none;
			}
		}
	}
	&__mass{
		margin-top: 2.2em;
		position: relative;
		@media (max-width: $max-sm) {
			margin-top: 2.65em;
		}
	}
	&__arrow{
		position: absolute;
		top: 47.6%;
		transform: translateY(-50%);
		width: 3.15em;
		height: 3.15em;
		border-radius: 50%;
		background: #FFF0C2;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		@include trs;
		&:hover{
			opacity: 0.5;
		}
		@media (max-width: $max-sm) {
			display: none;
		}
		img{
			width: 0.965em;
			height: 1.4em;
		}
		&-prev{
			left: 1.1em;
		}
		&-next{
			right: 1.1em;
			img{
				transform: scale(-1, 1);
			}
		}
	}
	&__slider{
		margin: 0 6.1em;
		overflow: hidden;
		cursor: grab;
		&:active{
			cursor: grabbing
		}
		@media (max-width: $max-sm) {
			margin: 0;
		}
		.slick-list{
			overflow: visible;
			margin-left: -2em;
			@media (max-width: $max-sm) {
				margin-left: -1.4em;
			}
		}
		&.slick-dotted.slick-slider{
			margin-bottom: 0;
		}
		.slick-dots{
			padding-top: 0.2em;
			display: flex;
			justify-content: center;
			position: relative;
			bottom: 0;
			@media (max-width: $max-sm) {
				padding-top: 1em;
			}
			li{
				width: 1em;
				height: 1em;
				border: 0.08em solid #C4C4C4;
				border-radius: 50%;
				margin: 0 0.46em;
				@include trs;
				@media (max-width: $max-sm) {
					width: 1.2em;
					height: 1.2em;
				}
				button{
					display: none;
				}
				&.slick-active{
					background-color: #C4C4C4;
				}
			}
		}
	}
	&__box{
		padding-left: 2em;
		padding-top: 1.8em;
		padding-bottom: 1.8em;
		@media (max-width: $max-sm) {
			padding-left: 1.4em;
			padding-top: 0.55em;
			padding-bottom: 0.55em;
		}
	}
	&__item{
		border-radius: 0.8em;
		padding: 2.3em 0.3em 0 0.3em;
		position: relative;
		@media (max-width: $max-sm) {
			padding: 6.2em 0 0 0;
		}
	}
	&__name{
		font-weight: 600;
		font-size: 0.93em;
		line-height: 170%;
		color: #5A5A5A;
		text-align: center;
		padding-bottom: 0.5em;
		@media (max-width: $max-sm) {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			padding: 1.6em 0.3em 0 0.3em;
			font-weight: 500;
			font-size: 0.9em;
			line-height: 1.3em;
		}
	}
	&__volume{
		@media (max-width: $max-sm) {
			padding-top: 0.4em;
			display: block;
			font-weight: 500;
			font-size: 0.9em;
			line-height: 1.5em;
			text-align: center;
			color: #5A5A5A;
			opacity: 0.5;
		}
	}
	&__image{
		margin: 0 auto;
		max-width: 100%;
		height: 16.5em;
		@media (max-width: $max-sm) {
			height: 10.8em;
		}
	}
	&__btn{
		padding-top: 2.9em;
		text-align: center;
		@media (max-width: $max-sm) {
			padding-top: 1.95em;
		}
		a{
			padding: 1em 1.5em 1em 1.5em;
			@media (max-width: $max-sm) {
				padding: 1.6em 2.6em 1.7em 2.6em;
			}
		}
	}
}