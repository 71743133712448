.boost{
	padding-top: 6.38em;
	padding-bottom: 10.7em;
	background: #F9F9F9;
	@media (max-width: $max-sm) {
		padding-top: 5.55em;
		padding-bottom: 9.4em;
	}
	&__wrapper{
		position: relative;
		z-index: 1;
	}
	&__circle{
		position: absolute;
		right: 15.65em;
		bottom: -8.1em;
		width: 83.6em;
		height: 45.32em;
		z-index: -1;
		border-radius: 50%;
		background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__leaf{
		&-1{
			position: absolute;
			left: 25em;
			top: 4.3em;
			width: 10.2em;
			pointer-events: none;
			@media (max-width: $max-sm) {
				left: auto;
				right: -6.8em;
				top: 13em;
			}
		}
		&-2{
			position: absolute;
			right: -4.4em;
			bottom: -7.9em;
			width: 21.4em;
			z-index: -1;
			pointer-events: none;
			@media (max-width: $max-sm) {
				right: auto;
				left: -2.5em;
				bottom: -15.4em;
				transform: scale(-1, 1) rotate(-9deg);
			}
		}
	}
	&__title{
		text-align: center;
		@media (max-width: $max-sm) {
			display: none;
		}
		&-mob{
			display: none;
			font-weight: 700;
			font-size: 1.66em;
			line-height: 1.45em;
			text-align: center;
			color: #5A5A5A;
			@media (max-width: $max-sm) {
				display: block;
			}
		}
	}
	&__titlesub{
		padding-top: 0.85em;
		font-weight: 500;
		font-size: 1.365em;
		line-height: 1.455em;
		text-align: center;
		color: rgba(54, 54, 54, 0.5);
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__dyad{
		padding-top: 5.8em;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		@media (max-width: $max-sm) {
			flex-wrap: wrap;
			padding-top: 1.95em;
		}
	}
	&__left{
		width: 43.4%;
		@media (max-width: $max-sm) {
			width: 100%;
		}
	}
	&__topic{
		padding-left: 1.6em;
		padding-bottom: 1.2em;
		font-weight: 700;
		font-size: 1.24em;
		line-height: 1.4em;
		color: #5A5A5A;
		@media (max-width: $max-sm) {
			padding-left: 0;
			text-align: center;
			font-size: 1.27em;
			line-height: 1.28em;
			margin: 0 -1.4em;
			padding-bottom: 1.06em;
		}
	}
	&__list{
		background: #EDEDED;
		box-shadow: 0px 45px 50px rgba(0, 0, 0, 0.05);
		border-radius: 1em;
		padding: 0.7em;
		@media (max-width: $max-sm) {
			margin: 0 -1.4em;
			padding: 0.28em 0;
			border-radius: 0;
		}
	}
	&__point{
		background: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 0.7em 1em 0.71em 1.8em;
		font-weight: 500;
		font-size: 1.118em;
		line-height: 1.32em;
		color: #5A5A5A;
		margin-bottom: 0.2em;
		@media (max-width: $max-sm) {
			margin-bottom: 0.28em;
			font-size: 1.195em;
			line-height: 1.39em;
			padding: 0.65em 1em 0.7em 1.2em;
		}
		&:before{
			content: '';
			display: block;
			min-width: 2.24em;
			max-width: 2.24em;
			height: 2.24em;
			border-radius: 50%;
			background-image: url(../img/icon/check.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			margin-right: 1.6em;
			@media (max-width: $max-sm) {
				min-width: 2.32em;
				max-width: 2.32em;
				height: 2.32em;
			}
		}
		&:first-child{
			border-radius: 1em 1em 0 0;
			@media (max-width: $max-sm) {
				border-radius: 0;
			}
		}
		&:last-child{
			border-radius: 0 0 1em 1em;
			@media (max-width: $max-sm) {
				border-radius: 0;
			}
		}
	}
	&__right{
		width: 48.1%;
		padding-top: 0.2em;
		padding-right: 4em;
		@media (max-width: $max-sm) {
			width: 100%;
			padding-right: 0;
			padding-top: 0;
		}
	}
	&__text{
		font-weight: 300;
		font-size: 0.995em;
		line-height: 1.6em;
		color: #454545;
		@media (max-width: $max-sm) {
			display: none;
		}
		&-mob{
			display: none;
			padding-top: 2.1em;
			font-weight: 300;
			font-size: 1.1em;
			line-height: 1.5em;
			color: #454545;
			p{
				&:not(:last-child){
					padding-bottom: 0.8em;
				}
			}
			@media (max-width: $max-sm) {
				display: block;
			}
		}
	}
	&__figure{
		width: 26.74em;
		height: 26.74em;
		border-radius: 50%;
		background: #F6B518;
		margin-top: 4.5em;
		margin-left: 1.7em;
		position: relative;
		@media (max-width: $max-sm) {
			width: 20em;
			height: 20em;
			margin-top: 3.15em;
			margin-left: auto;
			margin-right: -0.7em;
		}
	}
	&__image{
		position: absolute;
		left: 41%;
		top: 52.8%;
		transform: translate(-50%, -50%);
		width: 177%;
		pointer-events: none;
		@media (max-width: $max-sm) {
			top: 49.8%;
		}
	}
}