.assortment{
	padding-top: 6.2em;
	padding-bottom: 7.3em;
	@media (max-width: $max-sm) {
		padding-top: 7.85em;
		padding-bottom: 5.4em;
	}
	&__title{
		text-align: center;
		@media (max-width: $max-sm) {
			display: none;
		}
		&-mob{
			display: none;
			text-align: center;
			font-weight: 700;
			font-size: 1.66em;
			line-height: 1.45em;
			color: #5A5A5A;
			@media (max-width: $max-sm) {
				display: block;
			}
		}
	}
	&__titlesub{
		&-mob{
			padding-top: 1.02em;
			font-weight: 500;
			font-size: 1.29em;
			line-height: 1.5em;
			text-align: center;
			color: #9B9B9B;
			display: none;
			@media (max-width: $max-sm) {
				display: block;
			}
		}
	}
	&__mass{
		padding-top: 1.15em;
		@media (max-width: $max-sm) {
			padding-top: 1.57em;
		}
	}
	&__set{
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-left: -1.95em;
		@media (max-width: $max-sm) {
			flex-wrap: wrap;
		}
	}
	&__box{
		width: 33.3%;
		padding-top: 2.87em;
		padding-left: 1.95em;
		@media (max-width: $max-sm) {
			width: 100%;
			padding-top: 2.35em;
		}
	}
	&__item{
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		border-radius: 0.6em;
		padding: 2.5em 2.2em 1.3em 2.2em;
		min-height: 24.31em;
		position: relative;
		z-index: 1;
		overflow: hidden;
		@media (max-width: $max-sm) {
			text-align: center;
			padding: 3em 2em 1.6em 2em;
			min-height: 26.5em;
		}
	}
	&__aloft{
		width: 100%;
		padding: 0 0.3em;
	}
	&__name{
		font-weight: 600;
		font-size: 1.25em;
		line-height: 1.28em;
		color: #5A5A5A;
		@media (max-width: $max-sm) {
			font-size: 1.46em;
			line-height: 1.38em;
			br{
				display: none;
			}
		}
	}
	&__text{
		padding-top: 0.7em;
		font-weight: 300;
		font-size: 0.87em;
		line-height: 150%;
		color: #5A5A5A;
		@media (max-width: $max-sm) {
			padding-top: 0.6em;
			font-size: 1.1em;
			line-height: 1.5em;
			br{
				display: none;
			}
		}
	}
	&__image{
		height: 12.81em;
		pointer-events: none;
		@media (max-width: $max-sm) {
			height: 12.88em;
		}
	}
	&__circle{
		position: absolute;
		bottom: -8em;
		left: 50%;
		transform: translateX(-50%);
		width: 27.24em;
		height: 21.7em;
		z-index: -1;
	}
	&__btn{
		padding-top: 3.8em;
		text-align: center;
		@media (max-width: $max-sm) {
			padding-top: 3.6em;
		}
		a{
			padding: 1em 1.8em 1.1em 1.8em;
			@media (max-width: $max-sm) {
				padding: 1.7em 1.1em 1.7em 1.1em;
			}
		}
	}
}