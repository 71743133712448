.hat{
	background: #F9F9F9;
	padding-top: 1.2em;
	padding-bottom: 1.2em;
	@media (max-width: $max-sm) {
		padding-top: 1.35em;
		padding-bottom: 1.35em;
	}
	&__container{
		max-width: 67em;
		width: 100%;
		margin: 0 auto;
		padding: 0 0.95em;
		@media (max-width: $max-lg) {
			padding: 0 1.4em;
			max-width: 74em;
		}
		@media (min-width: $min-xl) {
			max-width: 63.8em;
			padding: 0 0.7em;
		}
	}
	&__wrapper{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		position: relative;
		z-index: 4;
	}
	&__left{
		padding-left: 1.3em;
		width: 52%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		@media (max-width: $max-sm) {
			width: 100%;
			justify-content: center;
			padding-left: 0;
		}
	}
	&__logotype{
		display: inline-flex;
		&-link{
			display: inline-flex;
			img{
				width: 10.26em;
				@media (max-width: $max-sm) {
					width: 11.15em;
				}
			}
		}
	}
	&__company{
		max-width: 26em;
		padding-left: 2.8em;
		font-size: 0.75em;
		line-height: 1.3em;
		color: #5A5A5A;
		position: relative;
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	
	&__right{
		padding-top: 0.7em;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		width: 48%;
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__network{
		text-align: right;
		padding-right: 3.5em;
		&-title{
			font-size: 0.625em;
			line-height: 1.2em;
			color: #5A5A5A;
		}
		&-set{
			display: flex;
			align-items: flex-start;
			justify-content: center;
			flex-wrap: wrap;
		}
		&-box{
			display: inline-flex;
			padding: 0.5em 0 0 0.73em;
		}
		&-link{
			width: 1.7em;
			height: 1.7em;
			border-radius: 50%;
			overflow: hidden;
			@include trs;
			img{
				width: 100%;
				height: 100%;
			}
			&:hover{
				opacity: 0.5;
			}
		}
	}
	&__phone{
		text-align: right;
		&-title{
			font-size: 0.625em;
			line-height: 1.2em;
			color: #5A5A5A;
			padding-bottom: 0.5em;
		}
		&-link{
			a{
				font-weight: 500;
				font-size: 1.25em;
				line-height: 1.25em;
				color: #5A5A5A;
				text-decoration: none;
				@include trs;
				&:hover{
					color: #F6B518;
				}
			}
		}
	}
}