.popup{
	display: none;
	background-color: #ffffff;
	font-family: 'Montserrat';
	max-height: 95%;
	width: 47.25em;
	border-radius: 1em;
	@media (max-width: $max-sm) {
		width: 26.6em;
		box-shadow: 0px 25.7905px 45.8498px rgba(0, 0, 0, 0.05);
	}
	&.fancybox-content{
		padding: 0;
		.fancybox-close-small{
			font-size: 1em;
			width: 2.7em;
			height: 2.7em;
			top: 0.18em;
			right: 0.18em;
			opacity: 1;
			padding: 0;
			@include trs;
			&:hover{
				opacity: 0.5;
			}
			@media (max-width: $max-sm) {
				top: 0.6em;
				right: 0.6em;
				width: 3.2em;
				height: 3.2em;
			}
			svg{
				width: 100%;
				height: 100%;
				path{
					fill: #F6B518;
				}
			}
		}
	}
	&__couple{
		border-radius: 1em;
		display: flex;
		align-items: flex-start;
		padding: 3.16em 4.5em 3.3em 3.2em;
		overflow: hidden;
		position: relative;
		z-index: 1;
		@media (max-width: $max-sm) {
			padding: 4.5em 2em 3em 2em;
			background: #EDEDED;
			&:before{
				content: '';
				position: absolute;
				top: 0.56em;
				right: 0.56em;
				bottom: 0.56em;
				left: 0.56em;
				background-color: #ffffff;
				border-radius: 1em;
				z-index: -1;
			}
		}
	}
	&__leaf{
		&-1{
			position: absolute;
			left: 0;
			top: 4em;
			z-index: -1;
			pointer-events: none;
			width: 26.5em;
			@media (max-width: $max-sm) {
				display: none;
			}
		}
	}
	&__circle{
		position: absolute;
		left: -11.7em;
		top: -12.5em;
		width: 33.2em;
		height: 33.2em;
		background: #F6B518;
		border-radius: 50%;
		z-index: -1;
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__other{
		width: 55.2%;
		padding-top: 0.9em;
		padding-right: 2.9em;
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__img{
		width: 14.85em;
		margin-left: 1.5em;
		pointer-events: none;
	}
	&__attention{
		position: relative;
		z-index: 2;
		margin-top: -2.3em;
		font-weight: 500;
		font-size: 0.745em;
		line-height: 1.7em;
		text-align: center;
		color: #787878;
		span{
			color: #F6B518;
		}
	}
	&__shape{
		width: 44.8%;
		@media (max-width: $max-sm) {
			width: 100%;
		}
	}
	&__title{
		font-weight: 600;
		font-size: 1.18em;
		line-height: 1.55em;
		text-align: center;
		text-transform: uppercase;
		color: #5A5A5A;
		@media (max-width: $max-sm) {
			font-size: 1.67em;
			line-height: 1.2em;
			br{
				display: none;
			}
		}
	}
	&__text{
		padding-top: 0.3em;
		font-weight: 400;
		font-size: 0.74em;
		line-height: 1.3em;
		text-align: center;
		color: #787878;
		@media (max-width: $max-sm) {
			padding-top: 0.5em;
			font-size: 1em;
			line-height: 1.4em;
		}
	}
	&__form{
		padding-top: 1em;
		@media (max-width: $max-sm) {
			padding-top: 1.8em;
			max-width: 92%;
			margin: 0 auto;
		}
	}
	&__row{
		padding-bottom: 0.77em;
		@media (max-width: $max-sm) {
			padding-bottom: 0.65em;
		}
	}
	&__icon{
		position: absolute;
		left: 1.2em;
		top: 50%;
		transform: translateY(-50%);
		width: 1.82em;
		height: 1.82em;
		border-radius: 50%;
		pointer-events: none;
		@media (max-width: $max-sm) {
			left: 1em;
			width: 1.88em;
			height: 1.88em;
		}
	}
	&__field{
		position: relative;
		input{
			display: block;
			width: 100%;
			background: #F9F9F9;
			border-radius: 0.5em;
			font-family: 'Montserrat';
			font-weight: 500;
			font-size: 0.74em;
			line-height: 1.5em;
			color: #5A5A5A;
			border: none;
			padding: 2em 1em 1.95em 5.6em;
			@include trs;
			&::placeholder{
				color: #AFAFAF;
			}
			&.invalid{
				box-shadow: inset 0px 2px 6px rgba(255, 0, 0, 0.4);
			}
			@media (max-width: $max-sm) {
				padding: 1.9em 1em 1.85em 5.2em;
			}
		}
	}
	&__note{
		padding-top: 0.8em;
		display: none;
		font-size: 0.83em;
		line-height: 1.22em;
		text-align: center;
		color: #A0A0A0;
		@media (max-width: $max-sm) {
			display: block;
		}
	}
	&__btn{
		padding-top: 0.5em;
		position: relative;
		width: 100%;
		text-align: center;
		@media (max-width: $max-sm) {
			padding-top: 1.3em;
		}
		button{
			padding: 1.1em 1.7em 1.1em 1.7em;
			font-size: 1.1em;
			@media (max-width: $max-sm) {
				padding: 1.1em 2.6em 1.1em 2.6em;
				font-size: 1em;
				letter-spacing: 0.04em;
			}
		}
	}
	&__consent{
		padding-top: 0.9em;
		font-weight: 500;
		font-size: 0.68em;
		line-height: 1.2em;
		text-align: center;
		color: #C6C2C2;
		@media (max-width: $max-sm) {
			padding-top: 0.5em;
			font-size: 0.82em;
			line-height: 1.46em;
		}
		a{
			color: #C6C2C2;
			font-weight: 600;
			@include trs;
			&:hover{
				opacity: 0.5;
			}
		}
	}
}

.fancybox-bg{
	background: rgba(0, 0, 0, 0.5);
}