$min-xl: 1920px;
$max-xl: 1919px;

$min-lg: 992px;
$max-lg: 991px;

$min-sm: 768px;
$max-sm: 767px;

@mixin trs {
	transition: all .3s;
}