*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body{
	font-size: calc(.0114 * 100vw + 0px);
	&:before{
		cursor: pointer;
		content: '';
		z-index: 12;
		display: block;
		position: fixed;
		left: 0;
		top: 0;	
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		background-image: none;
		opacity: 0;
		visibility: hidden;
		transition: all .5s;
	}
	&.no-scroll{
		overflow: hidden;
		&:before{
			opacity: 1;
			visibility: visible;
		}
	}
	@media (max-width: $max-sm) {
		font-size: calc(.034 * 100vw + 0px);
	}
	@media (min-width: $min-xl) {
		font-size: calc(.00838 * 100vw + 0px);
	}
}

.wrapper{
	font-family: 'Montserrat';
	font-weight: 400;
	position: relative;
	overflow: hidden;
	background-color: #ffffff;
}

.container__center{
	max-width: 73em;
	width: 100%;
	margin: 0 auto;
	padding: 0 0.95em;
	@media (max-width: $max-lg) {
		padding: 0 1.4em;
		max-width: 74em;
	}
	@media (min-width: $min-xl) {
		max-width: 72.3em;
		padding: 0 0.7em;
	}
}

.title{
	font-weight: 700;
	font-size: 2.18em;
	line-height: 1.3em;
	color: #5A5A5A;
	@media (max-width: $max-sm) {
		font-size: 1.65em;
		line-height: 1.42em;
	}
}