@charset "UTF-8";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: calc(1.14vw + 0px);
}
body:before {
  cursor: pointer;
  content: "";
  z-index: 12;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}
body.no-scroll {
  overflow: hidden;
}
body.no-scroll:before {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  body {
    font-size: calc(3.4vw + 0px);
  }
}
@media (min-width: 1920px) {
  body {
    font-size: calc(0.838vw + 0px);
  }
}

.wrapper {
  font-family: "Montserrat";
  font-weight: 400;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
}

.container__center {
  max-width: 73em;
  width: 100%;
  margin: 0 auto;
  padding: 0 0.95em;
}
@media (max-width: 991px) {
  .container__center {
    padding: 0 1.4em;
    max-width: 74em;
  }
}
@media (min-width: 1920px) {
  .container__center {
    max-width: 72.3em;
    padding: 0 0.7em;
  }
}

.title {
  font-weight: 700;
  font-size: 2.18em;
  line-height: 1.3em;
  color: #5A5A5A;
}
@media (max-width: 767px) {
  .title {
    font-size: 1.65em;
    line-height: 1.42em;
  }
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(89.96deg, #F3A810 7.9%, #F9CA25 97.96%);
  z-index: 100;
}

.loader .loader__main {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14em;
  height: 20em;
  margin: 0;
  transform: translate(-50%, -50%);
  animation: upward infinite;
  animation-duration: 1.5s;
}

@media (max-width: 767px) {
  .loader__main {
    width: 8em;
    height: 14em;
  }
}
.loader__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

body.compensate-for-scrollbar {
  overflow: hidden;
}

.fancybox-active {
  height: auto;
}

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden;
}

.fancybox-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}

.fancybox-container * {
  box-sizing: border-box;
}

.fancybox-bg, .fancybox-inner, .fancybox-outer, .fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: 0.87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-caption, .fancybox-infobar, .fancybox-navigation .fancybox-button, .fancybox-toolbar {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  visibility: hidden;
  z-index: 99997;
}

.fancybox-show-caption .fancybox-caption, .fancybox-show-infobar .fancybox-infobar, .fancybox-show-nav .fancybox-navigation .fancybox-button, .fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  visibility: visible;
}

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-toolbar {
  right: 0;
  top: 0;
}

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px 44px 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}

.fancybox-slide:before {
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide, .fancybox-slide--current, .fancybox-slide--next, .fancybox-slide--previous {
  display: block;
}

.fancybox-slide--next {
  z-index: 99995;
}

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0 0;
}

.fancybox-slide--image:before {
  display: none;
}

.fancybox-slide--html {
  padding: 6px 6px 0;
}

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0 0 44px;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995;
}

.fancybox-slide--html .fancybox-content {
  margin: 0 0 6px;
}

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}

.fancybox-can-pan .fancybox-content, .fancybox-can-swipe .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-container [data-selectable=true] {
  cursor: text;
}

.fancybox-image, .fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--iframe .fancybox-content, .fancybox-slide--map .fancybox-content, .fancybox-slide--video .fancybox-content {
  height: calc(100% - 44px);
  overflow: visible;
  padding: 0;
  width: 100%;
}

.fancybox-slide--video .fancybox-content {
  background: #000;
}

.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}

.fancybox-iframe, .fancybox-video {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  vertical-align: top;
  width: 100%;
}

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color 0.2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
}

.fancybox-button, .fancybox-button:link, .fancybox-button:visited {
  color: #ccc;
}

.fancybox-button:hover {
  color: #fff;
}

.fancybox-button:focus {
  outline: none;
}

.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}

.fancybox-button.disabled, .fancybox-button.disabled:hover, .fancybox-button[disabled], .fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none;
}

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}

.fancybox-button--fsenter svg:nth-child(2), .fancybox-button--fsexit svg:nth-child(1), .fancybox-button--pause svg:nth-child(1), .fancybox-button--play svg:nth-child(2) {
  display: none;
}

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0;
  transform-origin: 0;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: linear;
  z-index: 99998;
}

.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: 0.8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401;
}

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0;
}

.fancybox-is-scaling .fancybox-close-small, .fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}

.fancybox-navigation .fancybox-button {
  background: transparent;
  height: 100px;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px;
}

.fancybox-navigation .fancybox-button div {
  background: rgba(30, 30, 30, 0.6);
  height: 100%;
  padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  padding: 31px 26px 31px 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
}

.fancybox-caption {
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px;
  right: 0;
}

.fancybox-caption:before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEtCAQAAABjBcL7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHRJREFUKM+Vk8EOgDAIQ0vj/3+xBw8qIZZueFnIKC90MCAI8DlrkHGeqqGIU6lVigrBtpCWqeRWoHDNqs0F7VNVBVxmHRlvoVqjaYkdnDIaivH2HqZ5+oZj3JUzWB+cOz4G48Bg+tsJ/tqu4dLC/4Xb+0GcF5BwBC0AA53qAAAAAElFTkSuQmCC);
  background-repeat: repeat-x;
  background-size: contain;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -25px;
  z-index: -1;
}

.fancybox-caption:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  content: "";
  display: block;
  left: 44px;
  position: absolute;
  right: 44px;
  top: 0;
}

.fancybox-caption a, .fancybox-caption a:link, .fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}

.fancybox-loading {
  -webkit-animation: a 1s linear infinite;
  animation: a 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999;
}

@-webkit-keyframes a {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes a {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.fancybox-fx-fade.fancybox-slide--next, .fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-1turn);
  transform: rotate(-1turn);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
}

.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1);
}

@media (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px;
  }

  .fancybox-slide--image {
    padding: 6px 0 0;
  }

  .fancybox-slide--image .fancybox-content {
    margin-bottom: 6px;
  }

  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px;
  }
}
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.fancybox-share__button:link, .fancybox-share__button:visited {
  color: #fff;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}

.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px;
}

.fancybox-thumbs__list a:before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}

.fancybox-thumbs__list a:focus:before {
  opacity: 0.5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active:before {
  opacity: 1;
}

@media (max-width: 768px) {
  .fancybox-thumbs {
    width: 110px;
  }

  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }

  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px);
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Light.eot");
  src: url("../fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Light.woff2") format("woff2"), url("../fonts/Montserrat-Light.woff") format("woff"), url("../fonts/Montserrat-Light.ttf") format("truetype"), url("../fonts/Montserrat-Light.svg#Montserrat-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.eot");
  src: url("../fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat-Regular.ttf") format("truetype"), url("../fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.eot");
  src: url("../fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Medium.woff2") format("woff2"), url("../fonts/Montserrat-Medium.woff") format("woff"), url("../fonts/Montserrat-Medium.ttf") format("truetype"), url("../fonts/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.eot");
  src: url("../fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/Montserrat-SemiBold.woff") format("woff"), url("../fonts/Montserrat-SemiBold.ttf") format("truetype"), url("../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.eot");
  src: url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat-Bold.ttf") format("truetype"), url("../fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.eot");
  src: url("../fonts/Inter-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Inter-Regular.woff2") format("woff2"), url("../fonts/Inter-Regular.woff") format("woff"), url("../fonts/Inter-Regular.ttf") format("truetype"), url("../fonts/Inter-Regular.svg#Inter-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Bold.eot");
  src: url("../fonts/Inter-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Inter-Bold.woff2") format("woff2"), url("../fonts/Inter-Bold.woff") format("woff"), url("../fonts/Inter-Bold.ttf") format("truetype"), url("../fonts/Inter-Bold.svg#Inter-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto Slab";
  src: url("../fonts/RobotoSlab-Regular.eot");
  src: url("../fonts/RobotoSlab-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSlab-Regular.woff2") format("woff2"), url("../fonts/RobotoSlab-Regular.woff") format("woff"), url("../fonts/RobotoSlab-Regular.ttf") format("truetype"), url("../fonts/RobotoSlab-Regular.svg#RobotoSlab-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eeeeee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #A1A2A1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #A1A2A1;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
input:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
input:focus {
  outline: none;
}

.button {
  text-decoration: none;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  font-family: "Inter";
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(89.96deg, #F3A810 7.9%, #F9CA25 97.96%);
  box-shadow: 0px 12px 15px rgba(250, 200, 34, 0.4);
  border-radius: 0.6em;
  color: #fefefe;
  font-size: 1.24em;
  text-transform: uppercase;
  border: none;
  font-weight: 700;
  transition: all 0.3s;
}
@media (max-width: 767px) {
  .button {
    font-size: 1.1em;
    border-radius: 0.8em;
  }
}
.button__icon {
  min-width: 1.7em;
  max-width: 1.7em;
  height: 1.9em;
  object-fit: contain;
  margin-right: 0.7em;
  pointer-events: none;
}
@media (max-width: 767px) {
  .button__icon {
    min-width: 2.02em;
    max-width: 2.02em;
    height: 2.28em;
    margin-right: 0.4em;
  }
}
.button:hover {
  color: #fefefe;
  box-shadow: none;
}
.button:focus {
  outline: none;
}

.button:before {
  content: "";
  display: block;
  width: 6em;
  height: 50em;
  margin-left: 8em;
  background: rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: 0 0 3em rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 3em rgba(255, 255, 255, 0.4);
  position: absolute;
  left: -3em;
  top: -25em;
  z-index: 1;
  transform: rotate(45deg);
  transition: all 0.3s;
  animation-name: shine;
  animation-duration: 4s;
  animation-delay: 0.3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@-webkit-keyframes shine {
  0% {
    left: -2em;
    margin-left: 0;
  }
  30% {
    left: 110%;
    margin-left: 3em;
  }
  100% {
    left: 110%;
    margin-left: 3em;
  }
}
@keyframes shine {
  0% {
    width: 2em;
    left: -2em;
    margin-left: 0;
  }
  30% {
    left: 110%;
    width: 2em;
    margin-left: 3em;
  }
  100% {
    width: 2em;
    left: 110%;
    margin-left: 3em;
  }
}
.form__couple {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.9em;
}
.form__row {
  padding-left: 1.9em;
  width: 50%;
  padding-bottom: 1.9em;
}
@media (max-width: 767px) {
  .form__row {
    width: 100%;
    padding-bottom: 2em;
  }
}
.form__field {
  width: 100%;
  border-radius: 2em;
  border: 1px solid #ff771f;
  color: #000000;
  font-family: "Roboto";
  font-size: 0.88em;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.01em;
  padding: 1.43em 1.6em;
  min-height: 3.275em;
}
.form__field::placeholder {
  color: rgba(0, 0, 0, 0.51);
}
.form__field.invalid {
  border: 1px solid red;
  color: red;
}
.form__field.invalid::placeholder {
  color: red;
}
@media (max-width: 767px) {
  .form__field {
    font-size: 0.9em;
    padding: 1.6em 1.65em;
  }
}
.form__knob {
  position: relative;
  width: 100%;
}
.form__knob-img {
  pointer-events: none;
  position: absolute;
  left: 1.5em;
  top: -1em;
  width: 5.91em;
  height: 6.41em;
  z-index: 2;
  object-fit: contain;
}
.form__btn {
  justify-content: center;
  font-weight: 400;
  padding: 0.9em 0.9em 0.9em 2em;
  min-height: 3.275em;
  border-radius: 2em;
}
.form__consent {
  padding-top: 1em;
  color: #000000;
  font-size: 0.765em;
  font-weight: 500;
  text-align: left;
  letter-spacing: 0.01em;
}
.form__consent-link {
  color: #000be1;
  text-decoration: none;
  transition: all 0.3s;
}
.form__consent-link:hover {
  opacity: 0.5;
}
@media (max-width: 767px) {
  .form__consent {
    font-size: 0.8em;
    max-width: 20.8em;
    margin: 0 auto;
    text-align: center;
  }
}

.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  z-index: 10;
  transition: all 0.3s;
}
@media (max-width: 767px) {
  .header {
    padding-top: 1.3em;
    padding-bottom: 1.3em;
  }
}
.header.scroll {
  position: fixed;
  background: #F9F9F9;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}
.header__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .header__wrapper {
    justify-content: space-between;
  }
}
.header__company {
  width: 33%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .header__company {
    width: auto;
  }
}
.header__company-logotype {
  display: flex;
}
.header__company-logotype a {
  display: flex;
  align-items: flex-start;
}
.header__company-logotype a img {
  width: 10.27em;
}
@media (max-width: 767px) {
  .header__company-logotype a img {
    width: 11.17em;
  }
}
.header__company-text {
  font-size: 0.748em;
  line-height: 1.4em;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 2.6em;
  padding-top: 0.1em;
}
@media (max-width: 767px) {
  .header__company-text {
    display: none;
  }
}
.header__menu {
  width: 48%;
  padding-left: 0.95em;
}
@media (max-width: 767px) {
  .header__menu {
    display: none;
  }
}
.header__contacts {
  width: 19%;
  text-align: right;
  padding-left: 1em;
  padding-right: 0.15em;
  padding-top: 0.1em;
}
@media (max-width: 767px) {
  .header__contacts {
    width: 100%;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    padding-top: 2.2em;
  }
}
.header__contacts-text {
  font-size: 0.558em;
  line-height: 1.3em;
  color: #5A5A5A;
  padding-bottom: 0.9em;
}
@media (max-width: 767px) {
  .header__contacts-text {
    font-size: 0.92em;
    line-height: 1.2em;
    padding-bottom: 0.6em;
  }
}
.header__contacts-phone a {
  text-decoration: none;
  font-weight: 500;
  font-size: 1.24em;
  line-height: 1.24em;
  color: #5A5A5A;
  transition: all 0.3s;
}
@media (max-width: 767px) {
  .header__contacts-phone a {
    font-size: 2.01em;
    line-height: 1.2em;
  }
}
.header__contacts-phone a:hover {
  color: #F3A810;
}
.header__part {
  display: none;
}
@media (max-width: 767px) {
  .header__part {
    display: flex;
    padding-left: 1em;
  }
}
.header__toggle {
  max-width: 3.7em;
  min-width: 3.7em;
  width: 100%;
  margin-left: auto;
  cursor: pointer;
  transition: all 0.3s;
}
.header__toggle span {
  display: block;
  height: 0.2em;
  width: 100%;
  margin-bottom: 0.78em;
  background-color: #000000;
  margin-left: auto;
  margin-right: 0.7em;
}
.header__toggle span:last-child {
  margin-bottom: 0;
}
.header__toggle:hover {
  opacity: 0.5;
}

.drop {
  position: fixed;
  left: -100%;
  top: 0;
  max-width: 29.4em;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 20;
  padding: 1.3em 1.4em 2.3em 1.4em;
  overflow: auto;
  transition: all 0.3s;
}
.drop.open {
  left: 0;
}
.drop__head {
  padding-bottom: 3.8em;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.drop__logotype {
  display: inline-flex;
}
.drop__logotype a {
  display: inline-flex;
  align-items: flex-start;
}
.drop__logotype a img {
  width: 11em;
}
.drop__close {
  width: 1.85em;
  height: 1.85em;
  margin-left: 2em;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.drop__close:hover {
  opacity: 0.5;
}
.drop__bonds {
  padding-top: 0.8em;
}
.drop__bonds-text {
  color: #5A5A5A;
  font-size: 0.92em;
  line-height: 1.2em;
  padding-bottom: 0.8em;
}
.drop__bonds-set {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.drop__bonds-set a {
  min-width: 7.9em;
  text-align: center;
  display: inline-block;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 1.55em;
  color: #FFFFFF;
  text-decoration: none;
  border-radius: 0.4em;
  padding: 0.18em 1.25em 0.21em 1.25em;
  margin-bottom: 0.7em;
  transition: all 0.3s;
}
.drop__bonds-set a:hover {
  opacity: 0.5;
}

.menu {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
}
.menu__item {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-right: 1.06em;
}
@media (max-width: 767px) {
  .menu__item {
    width: 100%;
    padding-top: 0;
    padding-bottom: 1.75em;
    padding-right: 0;
  }
}
.menu__link {
  text-decoration: none;
  font-size: 1em;
  line-height: 1em;
  color: #5A5A5A;
  transition: all 0.3s;
}
.menu__link:hover {
  color: #F3A810;
}
@media (max-width: 767px) {
  .menu__link {
    font-size: 1.46em;
  }
}

.footer {
  position: relative;
  padding-top: 2.25em;
  padding-bottom: 2.7em;
}
@media (max-width: 767px) {
  .footer {
    padding-top: 2.6em;
    padding-bottom: 2.2em;
  }
}
.footer__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .footer__wrapper {
    flex-wrap: wrap;
  }
}
.footer__left {
  width: 27%;
  padding-top: 0.5em;
}
@media (max-width: 767px) {
  .footer__left {
    display: none;
  }
}
.footer__logotype {
  display: inline-flex;
}
.footer__logotype-link {
  display: inline-flex;
}
.footer__logotype-link img {
  width: 12.06em;
}
.footer__company {
  padding-top: 1.1em;
  font-size: 0.87em;
  line-height: 1.15em;
  color: rgba(0, 0, 0, 0.5);
}
.footer__center {
  width: 27%;
}
@media (max-width: 767px) {
  .footer__center {
    width: 100%;
  }
}
.footer__data {
  font-weight: 300;
  font-size: 0.87em;
  line-height: 150%;
  color: #6F6F6F;
}
@media (max-width: 767px) {
  .footer__data {
    text-align: center;
    font-size: 1.1em;
    line-height: 1.52em;
  }
}
.footer__politics {
  padding-top: 0.8em;
}
@media (max-width: 767px) {
  .footer__politics {
    text-align: center;
  }
}
.footer__politics a {
  font-weight: 500;
  display: inline-block;
  font-size: 0.87em;
  line-height: 150%;
  color: #383838;
  transition: all 0.3s;
}
.footer__politics a:hover {
  color: #F6B518;
}
@media (max-width: 767px) {
  .footer__politics a {
    font-size: 1.1em;
  }
}
.footer__right {
  width: 31.6%;
  padding-right: 2em;
}
@media (max-width: 767px) {
  .footer__right {
    width: 100%;
    padding-right: 0;
  }
}
.footer__warning {
  font-weight: 300;
  font-size: 0.87em;
  line-height: 150%;
  color: #6F6F6F;
}
@media (max-width: 767px) {
  .footer__warning {
    display: none;
  }
}
.footer__network-set {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .footer__network-set {
    justify-content: center;
  }
}
.footer__network-box {
  display: inline-flex;
  padding: 1.4em 0.98em 0 0;
}
@media (max-width: 767px) {
  .footer__network-box {
    padding: 1em 0.4em 0 0.4em;
  }
}
.footer__network-link {
  display: inline-flex;
  justify-content: center;
  transition: all 0.3s;
}
.footer__network-link:hover {
  opacity: 0.8;
}
.footer__network-link img {
  max-width: 3.45em;
  max-height: 1.99em;
}
@media (max-width: 767px) {
  .footer__network-link img {
    max-width: 3.81em;
    max-height: 2.1em;
  }
}

.popup {
  display: none;
  background-color: #ffffff;
  font-family: "Montserrat";
  max-height: 95%;
  width: 47.25em;
  border-radius: 1em;
}
@media (max-width: 767px) {
  .popup {
    width: 26.6em;
    box-shadow: 0px 25.7905px 45.8498px rgba(0, 0, 0, 0.05);
  }
}
.popup.fancybox-content {
  padding: 0;
}
.popup.fancybox-content .fancybox-close-small {
  font-size: 1em;
  width: 2.7em;
  height: 2.7em;
  top: 0.18em;
  right: 0.18em;
  opacity: 1;
  padding: 0;
  transition: all 0.3s;
}
.popup.fancybox-content .fancybox-close-small:hover {
  opacity: 0.5;
}
@media (max-width: 767px) {
  .popup.fancybox-content .fancybox-close-small {
    top: 0.6em;
    right: 0.6em;
    width: 3.2em;
    height: 3.2em;
  }
}
.popup.fancybox-content .fancybox-close-small svg {
  width: 100%;
  height: 100%;
}
.popup.fancybox-content .fancybox-close-small svg path {
  fill: #F6B518;
}
.popup__couple {
  border-radius: 1em;
  display: flex;
  align-items: flex-start;
  padding: 3.16em 4.5em 3.3em 3.2em;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .popup__couple {
    padding: 4.5em 2em 3em 2em;
    background: #EDEDED;
  }
  .popup__couple:before {
    content: "";
    position: absolute;
    top: 0.56em;
    right: 0.56em;
    bottom: 0.56em;
    left: 0.56em;
    background-color: #ffffff;
    border-radius: 1em;
    z-index: -1;
  }
}
.popup__leaf-1 {
  position: absolute;
  left: 0;
  top: 4em;
  z-index: -1;
  pointer-events: none;
  width: 26.5em;
}
@media (max-width: 767px) {
  .popup__leaf-1 {
    display: none;
  }
}
.popup__circle {
  position: absolute;
  left: -11.7em;
  top: -12.5em;
  width: 33.2em;
  height: 33.2em;
  background: #F6B518;
  border-radius: 50%;
  z-index: -1;
}
@media (max-width: 767px) {
  .popup__circle {
    display: none;
  }
}
.popup__other {
  width: 55.2%;
  padding-top: 0.9em;
  padding-right: 2.9em;
}
@media (max-width: 767px) {
  .popup__other {
    display: none;
  }
}
.popup__img {
  width: 14.85em;
  margin-left: 1.5em;
  pointer-events: none;
}
.popup__attention {
  position: relative;
  z-index: 2;
  margin-top: -2.3em;
  font-weight: 500;
  font-size: 0.745em;
  line-height: 1.7em;
  text-align: center;
  color: #787878;
}
.popup__attention span {
  color: #F6B518;
}
.popup__shape {
  width: 44.8%;
}
@media (max-width: 767px) {
  .popup__shape {
    width: 100%;
  }
}
.popup__title {
  font-weight: 600;
  font-size: 1.18em;
  line-height: 1.55em;
  text-align: center;
  text-transform: uppercase;
  color: #5A5A5A;
}
@media (max-width: 767px) {
  .popup__title {
    font-size: 1.67em;
    line-height: 1.2em;
  }
  .popup__title br {
    display: none;
  }
}
.popup__text {
  padding-top: 0.3em;
  font-weight: 400;
  font-size: 0.74em;
  line-height: 1.3em;
  text-align: center;
  color: #787878;
}
@media (max-width: 767px) {
  .popup__text {
    padding-top: 0.5em;
    font-size: 1em;
    line-height: 1.4em;
  }
}
.popup__form {
  padding-top: 1em;
}
@media (max-width: 767px) {
  .popup__form {
    padding-top: 1.8em;
    max-width: 92%;
    margin: 0 auto;
  }
}
.popup__row {
  padding-bottom: 0.77em;
}
@media (max-width: 767px) {
  .popup__row {
    padding-bottom: 0.65em;
  }
}
.popup__icon {
  position: absolute;
  left: 1.2em;
  top: 50%;
  transform: translateY(-50%);
  width: 1.82em;
  height: 1.82em;
  border-radius: 50%;
  pointer-events: none;
}
@media (max-width: 767px) {
  .popup__icon {
    left: 1em;
    width: 1.88em;
    height: 1.88em;
  }
}
.popup__field {
  position: relative;
}
.popup__field input {
  display: block;
  width: 100%;
  background: #F9F9F9;
  border-radius: 0.5em;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 0.74em;
  line-height: 1.5em;
  color: #5A5A5A;
  border: none;
  padding: 2em 1em 1.95em 5.6em;
  transition: all 0.3s;
}
.popup__field input::placeholder {
  color: #AFAFAF;
}
.popup__field input.invalid {
  box-shadow: inset 0px 2px 6px rgba(255, 0, 0, 0.4);
}
@media (max-width: 767px) {
  .popup__field input {
    padding: 1.9em 1em 1.85em 5.2em;
  }
}
.popup__note {
  padding-top: 0.8em;
  display: none;
  font-size: 0.83em;
  line-height: 1.22em;
  text-align: center;
  color: #A0A0A0;
}
@media (max-width: 767px) {
  .popup__note {
    display: block;
  }
}
.popup__btn {
  padding-top: 0.5em;
  position: relative;
  width: 100%;
  text-align: center;
}
@media (max-width: 767px) {
  .popup__btn {
    padding-top: 1.3em;
  }
}
.popup__btn button {
  padding: 1.1em 1.7em 1.1em 1.7em;
  font-size: 1.1em;
}
@media (max-width: 767px) {
  .popup__btn button {
    padding: 1.1em 2.6em 1.1em 2.6em;
    font-size: 1em;
    letter-spacing: 0.04em;
  }
}
.popup__consent {
  padding-top: 0.9em;
  font-weight: 500;
  font-size: 0.68em;
  line-height: 1.2em;
  text-align: center;
  color: #C6C2C2;
}
@media (max-width: 767px) {
  .popup__consent {
    padding-top: 0.5em;
    font-size: 0.82em;
    line-height: 1.46em;
  }
}
.popup__consent a {
  color: #C6C2C2;
  font-weight: 600;
  transition: all 0.3s;
}
.popup__consent a:hover {
  opacity: 0.5;
}

.fancybox-bg {
  background: rgba(0, 0, 0, 0.5);
}

.major {
  position: relative;
  padding-bottom: 6.9em;
}
@media (max-width: 767px) {
  .major {
    padding-bottom: 3em;
  }
}
.major__wrapper {
  position: relative;
  z-index: 3;
}
@media (max-width: 767px) {
  .major__wrapper {
    padding-top: 1.8em;
  }
}
.major__nut-1 {
  position: absolute;
  right: 16.7em;
  top: 2.6em;
  width: 5em;
  z-index: -1;
}
@media (max-width: 767px) {
  .major__nut-1 {
    right: 20.7em;
    top: 16.3em;
    width: 4em;
  }
}
.major__nut-2 {
  position: absolute;
  right: -6.5em;
  top: 19.1em;
  width: 5em;
  z-index: -1;
}
@media (max-width: 767px) {
  .major__nut-2 {
    right: -1.5em;
    top: 30em;
    width: 4em;
  }
}
.major__nut-3 {
  position: absolute;
  right: 18.2em;
  top: 18.2em;
  width: 5.8em;
  z-index: -1;
}
@media (max-width: 767px) {
  .major__nut-3 {
    right: 22.8em;
    top: 32.2em;
    width: 4.8em;
  }
}
.major__leaf-1 {
  position: absolute;
  right: 16em;
  top: 2.5em;
  width: 9.4em;
  z-index: -1;
}
@media (max-width: 767px) {
  .major__leaf-1 {
    right: 20em;
    top: 16em;
    width: 8em;
  }
}
.major__leaf-2 {
  position: absolute;
  right: 21.7em;
  top: 0;
  width: 12em;
  z-index: -1;
}
@media (max-width: 767px) {
  .major__leaf-2 {
    display: none;
  }
}
.major__leaf-3 {
  position: absolute;
  right: -8em;
  top: 10.8em;
  width: 12em;
  z-index: -1;
}
@media (max-width: 767px) {
  .major__leaf-3 {
    right: -2.8em;
    top: 22em;
    width: 10em;
  }
}
.major__leaf-4 {
  position: absolute;
  right: -10.6em;
  top: 18em;
  width: 13.4em;
  z-index: -1;
}
@media (max-width: 767px) {
  .major__leaf-4 {
    right: -5.1em;
    top: 28.7em;
    width: 11.4em;
  }
}
.major__leaf-5 {
  position: absolute;
  right: 11.7em;
  top: 15.5em;
  width: 11.5em;
  z-index: -1;
}
@media (max-width: 767px) {
  .major__leaf-5 {
    right: 17em;
    top: 29.8em;
    width: 10em;
  }
}
.major__leaf-6 {
  position: absolute;
  left: -21.3em;
  bottom: -7em;
  width: 37em;
  z-index: -1;
}
@media (max-width: 767px) {
  .major__leaf-6 {
    display: none;
  }
}
.major__content {
  padding-top: 4em;
  padding-right: 25em;
  position: relative;
  z-index: 2;
}
@media (max-width: 767px) {
  .major__content {
    text-align: center;
    padding-top: 0;
    padding-right: 0;
  }
}
.major__title {
  font-size: 2.18em;
  line-height: 1.42em;
  color: #5A5A5A;
}
@media (max-width: 767px) {
  .major__title {
    display: none;
  }
}
.major__title-mob {
  display: none;
}
@media (max-width: 767px) {
  .major__title-mob {
    display: block;
    font-weight: 700;
    font-size: 2.75em;
    line-height: 1.35em;
    color: #5A5A5A;
  }
}
.major__text {
  font-weight: 500;
  font-size: 1.245em;
  line-height: 120.02%;
  color: #787878;
  padding-top: 1.1em;
}
@media (max-width: 767px) {
  .major__text {
    display: none;
  }
}
.major__text-mob {
  display: none;
}
@media (max-width: 767px) {
  .major__text-mob {
    padding-top: 0.56em;
    display: block;
    font-weight: 500;
    font-size: 1.47em;
    line-height: 144.52%;
    color: #787878;
  }
}
.major__figure {
  position: absolute;
  right: -2.5em;
  top: 3em;
  width: 22em;
  height: 22em;
  background: #FFCE10;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .major__figure {
    position: relative;
    right: 0;
    top: 0;
    width: 21em;
    height: 21em;
    margin: 1.6em auto 0 auto;
  }
}
.major__figure-image {
  position: absolute;
  left: 55%;
  top: 51%;
  transform: translate(-50%, -50%);
  width: 150%;
  height: 150%;
  object-fit: contain;
  pointer-events: none;
}
@media (max-width: 767px) {
  .major__figure-image {
    width: 140%;
    height: 140%;
  }
}
.major__knob {
  padding-top: 2.5em;
}
@media (max-width: 767px) {
  .major__knob {
    padding-top: 2.2em;
  }
}
.major__knob a {
  padding: 0.7em 1.4em 0.7em 1.4em;
}
@media (max-width: 767px) {
  .major__knob a {
    width: 100%;
    padding: 1.1em 0.9em 1.1em 0.8em;
  }
}
.major__knob-note {
  padding-top: 1.2em;
  padding-left: 0.2em;
  font-size: 1em;
  line-height: 1.4em;
  color: #9B9B9B;
}
@media (max-width: 767px) {
  .major__knob-note {
    padding-top: 0.8em;
    font-size: 1.1em;
    line-height: 1.22em;
    padding-left: 0;
    text-align: center;
  }
}
.major__benefits {
  padding-top: 2.8em;
  padding-right: 0.5em;
}
@media (max-width: 767px) {
  .major__benefits {
    padding-top: 6.5em;
    padding-right: 0;
  }
}

.benefits__set {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -2em;
}
.benefits__box {
  padding-left: 2em;
  width: 33.3%;
}
@media (max-width: 767px) {
  .benefits__box {
    width: 100%;
    padding-bottom: 2.4em;
  }
}
.benefits__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 100%;
  background: #FBFBFB;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  border-radius: 1.7em;
  z-index: 1;
  padding: 1.24em 8em 1.2em 2.55em;
}
@media (max-width: 767px) {
  .benefits__item {
    overflow: hidden;
    min-height: 8em;
    padding: 1.6em 6em 1.6em 2.25em;
  }
}
.benefits__icon {
  position: absolute;
  right: 1.3em;
  top: 50%;
  transform: translateY(-50%);
  height: 5.5em;
  max-width: 5.5em;
  z-index: -1;
  pointer-events: none;
}
@media (max-width: 767px) {
  .benefits__icon {
    right: -0.7em;
    height: 6em;
    max-width: 6em;
  }
}
.benefits__name {
  font-family: "Inter";
  font-weight: 400;
  font-size: 1em;
  line-height: 1.4em;
  color: #454545;
}
@media (max-width: 767px) {
  .benefits__name {
    font-size: 1.2em;
    line-height: 1.4em;
  }
}

.advantages {
  padding-top: 6.28em;
  padding-bottom: 7.4em;
  background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #F9F9F9 100%);
}
@media (max-width: 767px) {
  .advantages {
    padding-top: 5.4em;
    padding-bottom: 5.55em;
  }
}
.advantages__title {
  text-align: center;
}
@media (max-width: 767px) {
  .advantages__title {
    max-width: 22em;
    margin: 0 auto;
  }
}
.advantages__mass {
  padding-top: 0.6em;
}
@media (max-width: 767px) {
  .advantages__mass {
    padding-top: 3em;
  }
}
.advantages__set {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -2em;
}
.advantages__box {
  padding-top: 4.18em;
  padding-left: 2em;
  width: 33.3%;
}
@media (max-width: 767px) {
  .advantages__box {
    width: 100%;
    padding-top: 2.2em;
  }
}
@media (max-width: 767px) {
  .advantages__aloft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.advantages__icon {
  pointer-events: none;
  display: block;
  height: 8.43em;
}
@media (max-width: 767px) {
  .advantages__icon {
    height: 8.55em;
  }
}
.advantages__name {
  padding-top: 1.1em;
  font-weight: 600;
  font-size: 1.38em;
  line-height: 1.34em;
  color: #5A5A5A;
}
@media (max-width: 767px) {
  .advantages__name {
    font-size: 1.4em;
    line-height: 1.4em;
    padding-top: 0;
    padding-left: 0.5em;
  }
}
.advantages__text {
  padding-top: 0.6em;
  font-weight: 300;
  font-size: 1em;
  line-height: 1.61em;
  color: #454545;
}
.advantages__text b {
  font-weight: 500;
}
@media (max-width: 767px) {
  .advantages__text {
    font-size: 1.1em;
    line-height: 1.51em;
    padding-top: 1.3em;
  }
  .advantages__text br {
    display: none;
  }
}

.proof {
  padding-top: 6.3em;
  padding-bottom: 4.2em;
}
@media (max-width: 767px) {
  .proof {
    padding-top: 6.6em;
    padding-bottom: 2.4em;
  }
}
.proof__title {
  text-align: center;
  max-width: 35em;
  margin: 0 auto;
}
.proof__mass {
  padding-top: 3.2em;
  padding-left: 2.4em;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .proof__mass {
    padding-left: 0;
    padding-top: 3.5em;
  }
}
.proof__left {
  width: 26%;
}
@media (max-width: 767px) {
  .proof__left {
    order: 2;
    width: 100%;
  }
}
.proof__right {
  width: 30.2%;
}
@media (max-width: 767px) {
  .proof__right {
    order: 3;
    width: 100%;
  }
}
.proof__center {
  width: 43.8%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-bottom: 2.6em;
}
@media (max-width: 767px) {
  .proof__center {
    align-items: flex-start;
    order: 1;
    width: 100%;
    padding-bottom: 2.3em;
  }
}
.proof__item {
  margin-bottom: 1.3em;
}
@media (max-width: 767px) {
  .proof__item {
    margin-bottom: 3.2em;
  }
}
.proof__item-1 {
  margin-bottom: 2.9em;
}
@media (max-width: 767px) {
  .proof__item-1 {
    margin-bottom: 3.2em;
  }
}
.proof__item-2 {
  margin-bottom: 2.9em;
}
@media (max-width: 767px) {
  .proof__item-2 {
    margin-bottom: 3.2em;
  }
}
.proof__num {
  font-family: "Roboto Slab";
  font-size: 4.3em;
  line-height: 0.81em;
  background: linear-gradient(180deg, rgba(196, 196, 196, 0.72) 0%, rgba(196, 196, 196, 0.25) 84.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: rgba(196, 196, 196, 0.72);
}
@media (max-width: 767px) {
  .proof__num {
    font-size: 4.6em;
    line-height: 0.9em;
  }
}
.proof__name {
  font-weight: 600;
  font-size: 1.37em;
  line-height: 1.35em;
  color: #F6B518;
}
@media (max-width: 767px) {
  .proof__name {
    padding-top: 0.5em;
    font-size: 1.47em;
    line-height: 1.52em;
  }
}
.proof__text {
  padding-top: 0.6em;
  font-weight: 300;
  font-size: 1em;
  line-height: 1.61em;
  color: #454545;
}
.proof__text b {
  font-weight: 500;
}
@media (max-width: 767px) {
  .proof__text {
    font-size: 1.1em;
    line-height: 1.5em;
  }
  .proof__text br {
    display: none;
  }
}
.proof__figure {
  position: relative;
  width: 30.02em;
  height: 30.02em;
  margin-left: -1.8em;
}
@media (max-width: 767px) {
  .proof__figure {
    width: 27.3em;
    height: 27.3em;
    margin-left: -0.2em;
    margin-right: -0.6em;
  }
}
.proof__leaf-1 {
  position: absolute;
  bottom: -4em;
  left: -6em;
  width: 45.5em;
  pointer-events: none;
}
@media (max-width: 767px) {
  .proof__leaf-1 {
    bottom: -3.7em;
    left: -5.2em;
    width: 41.5em;
  }
}
.proof__leaf-2 {
  position: absolute;
  bottom: 8.7em;
  left: -4.2em;
  width: 31.5em;
  pointer-events: none;
}
@media (max-width: 767px) {
  .proof__leaf-2 {
    bottom: 7.7em;
    left: -3.7em;
    width: 29.5em;
  }
}
.proof__leaf-3 {
  position: absolute;
  bottom: 1.6em;
  left: -2.8em;
  width: 11.6em;
  pointer-events: none;
}
@media (max-width: 767px) {
  .proof__leaf-3 {
    bottom: 1.4em;
    left: -2.4em;
    width: 10.6em;
  }
}
.proof__leaf-4 {
  position: absolute;
  bottom: 3.8em;
  right: -3.5em;
  width: 17.2em;
  pointer-events: none;
}
@media (max-width: 767px) {
  .proof__leaf-4 {
    bottom: 3.8em;
    right: -3.2em;
    width: 15.2em;
  }
}
.proof__leaf-5 {
  position: absolute;
  bottom: 6.1em;
  right: -1.9em;
  width: 17em;
  pointer-events: none;
}
@media (max-width: 767px) {
  .proof__leaf-5 {
    bottom: 6.1em;
    right: -1.2em;
    width: 14em;
  }
}
.proof__circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 0.157em solid #F6B518;
  border-radius: 50%;
}
.proof__circle-1 {
  width: 100%;
  height: 100%;
  opacity: 0.15;
}
.proof__circle-2 {
  width: 86%;
  height: 86%;
  opacity: 0.3;
}
.proof__circle-3 {
  width: 75%;
  height: 75%;
  opacity: 0.5;
}
.proof__circle-4 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18.65em;
  height: 18.65em;
  background: #F6B518;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .proof__circle-4 {
    width: 17.02em;
    height: 17.02em;
  }
}
.proof__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-49.5%, -48.5%);
  width: 30em;
  pointer-events: none;
}
@media (max-width: 767px) {
  .proof__image {
    width: 27em;
  }
}

.catalog {
  background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #F9F9F9 100%);
  padding-top: 6.2em;
  padding-bottom: 7.3em;
}
@media (max-width: 767px) {
  .catalog {
    padding-top: 5.6em;
    padding-bottom: 5.5em;
  }
}
.catalog__title {
  text-align: center;
}
@media (max-width: 767px) {
  .catalog__title br {
    display: none;
  }
}
.catalog__mass {
  margin-top: 2.2em;
  position: relative;
}
@media (max-width: 767px) {
  .catalog__mass {
    margin-top: 2.65em;
  }
}
.catalog__arrow {
  position: absolute;
  top: 47.6%;
  transform: translateY(-50%);
  width: 3.15em;
  height: 3.15em;
  border-radius: 50%;
  background: #FFF0C2;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
.catalog__arrow:hover {
  opacity: 0.5;
}
@media (max-width: 767px) {
  .catalog__arrow {
    display: none;
  }
}
.catalog__arrow img {
  width: 0.965em;
  height: 1.4em;
}
.catalog__arrow-prev {
  left: 1.1em;
}
.catalog__arrow-next {
  right: 1.1em;
}
.catalog__arrow-next img {
  transform: scale(-1, 1);
}
.catalog__slider {
  margin: 0 6.1em;
  overflow: hidden;
  cursor: grab;
}
.catalog__slider:active {
  cursor: grabbing;
}
@media (max-width: 767px) {
  .catalog__slider {
    margin: 0;
  }
}
.catalog__slider .slick-list {
  overflow: visible;
  margin-left: -2em;
}
@media (max-width: 767px) {
  .catalog__slider .slick-list {
    margin-left: -1.4em;
  }
}
.catalog__slider.slick-dotted.slick-slider {
  margin-bottom: 0;
}
.catalog__slider .slick-dots {
  padding-top: 0.2em;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 0;
}
@media (max-width: 767px) {
  .catalog__slider .slick-dots {
    padding-top: 1em;
  }
}
.catalog__slider .slick-dots li {
  width: 1em;
  height: 1em;
  border: 0.08em solid #C4C4C4;
  border-radius: 50%;
  margin: 0 0.46em;
  transition: all 0.3s;
}
@media (max-width: 767px) {
  .catalog__slider .slick-dots li {
    width: 1.2em;
    height: 1.2em;
  }
}
.catalog__slider .slick-dots li button {
  display: none;
}
.catalog__slider .slick-dots li.slick-active {
  background-color: #C4C4C4;
}
.catalog__box {
  padding-left: 2em;
  padding-top: 1.8em;
  padding-bottom: 1.8em;
}
@media (max-width: 767px) {
  .catalog__box {
    padding-left: 1.4em;
    padding-top: 0.55em;
    padding-bottom: 0.55em;
  }
}
.catalog__item {
  border-radius: 0.8em;
  padding: 2.3em 0.3em 0 0.3em;
  position: relative;
}
@media (max-width: 767px) {
  .catalog__item {
    padding: 6.2em 0 0 0;
  }
}
.catalog__name {
  font-weight: 600;
  font-size: 0.93em;
  line-height: 170%;
  color: #5A5A5A;
  text-align: center;
  padding-bottom: 0.5em;
}
@media (max-width: 767px) {
  .catalog__name {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1.6em 0.3em 0 0.3em;
    font-weight: 500;
    font-size: 0.9em;
    line-height: 1.3em;
  }
}
@media (max-width: 767px) {
  .catalog__volume {
    padding-top: 0.4em;
    display: block;
    font-weight: 500;
    font-size: 0.9em;
    line-height: 1.5em;
    text-align: center;
    color: #5A5A5A;
    opacity: 0.5;
  }
}
.catalog__image {
  margin: 0 auto;
  max-width: 100%;
  height: 16.5em;
}
@media (max-width: 767px) {
  .catalog__image {
    height: 10.8em;
  }
}
.catalog__btn {
  padding-top: 2.9em;
  text-align: center;
}
@media (max-width: 767px) {
  .catalog__btn {
    padding-top: 1.95em;
  }
}
.catalog__btn a {
  padding: 1em 1.5em 1em 1.5em;
}
@media (max-width: 767px) {
  .catalog__btn a {
    padding: 1.6em 2.6em 1.7em 2.6em;
  }
}

.delivery {
  padding-top: 6.2em;
  padding-bottom: 8.4em;
  position: relative;
}
@media (max-width: 767px) {
  .delivery {
    padding-top: 5.6em;
    padding-bottom: 3.7em;
  }
}
.delivery__wrapper {
  padding-right: 31em;
  padding-left: 0.1em;
  position: relative;
  z-index: 2;
}
@media (max-width: 767px) {
  .delivery__wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}
.delivery__circle {
  position: absolute;
  right: -37em;
  top: 0.3em;
  width: 62.03em;
  height: 62.03em;
  background: #F6B518;
  border-radius: 50%;
  z-index: -1;
}
@media (max-width: 767px) {
  .delivery__circle {
    display: none;
  }
}
@media (max-width: 767px) {
  .delivery__title {
    display: none;
  }
}
.delivery__title-mob {
  display: none;
}
@media (max-width: 767px) {
  .delivery__title-mob {
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 1.65em;
    line-height: 1.46em;
    text-align: center;
    color: #5A5A5A;
  }
}
.delivery__titlesub {
  padding-top: 1.4em;
  font-weight: 500;
  font-size: 1.37em;
  line-height: 1.4em;
  color: #9B9B9B;
}
@media (max-width: 767px) {
  .delivery__titlesub {
    display: none;
  }
}
.delivery__car {
  position: absolute;
  bottom: -8.7em;
  left: 33.1em;
  width: 47em;
  pointer-events: none;
}
@media (min-width: 1920px) {
  .delivery__car {
    width: 53em;
  }
}
@media (max-width: 767px) {
  .delivery__car {
    position: relative;
    left: -0.4em;
    bottom: 0;
    width: 36.5em;
    margin-top: 2.2em;
  }
}
.delivery__list {
  padding-top: 3.65em;
  padding-left: 0.7em;
}
@media (max-width: 767px) {
  .delivery__list {
    padding-top: 0;
    padding-left: 0;
    margin-top: -0.8em;
  }
}
.delivery__row {
  max-width: 34em;
  padding-left: 3.35em;
  padding-top: 0.3em;
  margin-bottom: 2.5em;
  position: relative;
}
@media (max-width: 767px) {
  .delivery__row {
    padding-top: 0;
    padding-left: 3.95em;
    max-width: none;
    width: 100%;
    margin-bottom: 1.8em;
  }
}
.delivery__row:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5em;
  height: 2.5em;
  background-image: url(../img/icon/check.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
@media (max-width: 767px) {
  .delivery__row:before {
    width: 2.7em;
    height: 2.7em;
  }
}
.delivery__name {
  font-weight: 600;
  font-size: 1.37em;
  line-height: 1.37em;
  color: #454545;
}
.delivery__name span {
  color: #F6B518;
}
@media (max-width: 767px) {
  .delivery__name {
    display: none;
  }
}
.delivery__name-mob {
  display: none;
}
@media (max-width: 767px) {
  .delivery__name-mob {
    display: block;
    font-weight: 500;
    font-size: 1.285em;
    line-height: 1.44em;
    color: #454545;
  }
}
.delivery__text {
  padding-top: 0.8em;
  font-weight: 300;
  font-size: 0.994em;
  line-height: 1.6em;
  color: #454545;
}
@media (max-width: 767px) {
  .delivery__text {
    display: none;
  }
}
.delivery__gift {
  position: absolute;
  top: 12.9em;
  right: 0;
  background: #FFFFFF;
  box-shadow: 0px 48.0288px 42.6923px rgba(0, 0, 0, 0.05);
  border-radius: 1em;
  width: 32.4em;
  padding: 3.3em 1.82em 1em 1.82em;
}
@media (max-width: 767px) {
  .delivery__gift {
    display: none;
  }
}
.delivery__gift-icon {
  position: absolute;
  left: -2.2em;
  top: -2.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FAC924;
  box-shadow: 0px 12.8077px 16.0096px rgba(250, 200, 34, 0.4);
  width: 5.3em;
  height: 5.3em;
  border-radius: 50%;
}
.delivery__gift-icon img {
  width: 53%;
  height: 57%;
}
.delivery__gift-title {
  font-weight: 300;
  font-size: 1.24em;
  line-height: 150%;
  text-align: center;
  color: #454545;
}
.delivery__gift-title b {
  font-weight: 600;
}
.delivery__gift-dyad {
  padding-top: 0.4em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.delivery__gift-image {
  width: 47.5%;
  pointer-events: none;
}
.delivery__gift-content {
  width: 52.5%;
  padding-top: 2.2em;
  padding-left: 0.35em;
}
.delivery__gift-text {
  font-weight: 300;
  font-size: 1em;
  line-height: 150%;
  color: #454545;
}
.delivery__gift-note {
  padding-top: 2.2em;
  margin-left: -1em;
  font-weight: 300;
  font-size: 0.664em;
  line-height: 150%;
  color: #454545;
}

.making {
  padding-top: 6.15em;
  background: #F9F9F9;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767px) {
  .making {
    padding-top: 5.59em;
  }
}
.making__title {
  text-align: center;
  position: relative;
  z-index: 3;
}
@media (max-width: 767px) {
  .making__title {
    display: none;
  }
}
.making__title-mob {
  margin: 0 -0.3em;
  display: none;
  font-weight: 700;
  font-size: 1.65em;
  line-height: 1.42em;
  color: #5A5A5A;
  text-align: center;
}
@media (max-width: 767px) {
  .making__title-mob {
    display: block;
  }
}
.making__mass {
  padding-top: 3.8em;
  position: relative;
  padding-bottom: 7.3em;
}
@media (max-width: 767px) {
  .making__mass {
    padding-top: 2.8em;
    padding-bottom: 5.3em;
  }
}
.making__image {
  position: absolute;
  right: -10.9em;
  bottom: 0;
  width: 63em;
  pointer-events: none;
}
@media (max-width: 767px) {
  .making__image {
    display: none;
  }
}
.making__content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 3;
}
@media (max-width: 767px) {
  .making__content {
    flex-wrap: wrap;
  }
}
.making__vantage {
  width: 34%;
}
@media (max-width: 767px) {
  .making__vantage {
    width: 100%;
  }
}
.making__vantage-item:not(:last-child) {
  padding-bottom: 1.96em;
}
@media (max-width: 767px) {
  .making__vantage-item:not(:last-child) {
    padding-bottom: 2.4em;
  }
}
.making__vantage-name {
  font-weight: 600;
  font-size: 1.365em;
  line-height: 1.37em;
  color: #F6B518;
}
@media (max-width: 767px) {
  .making__vantage-name {
    font-size: 1.47em;
    line-height: 1.52em;
  }
}
.making__vantage-text {
  padding-top: 0.8em;
  font-weight: 300;
  font-size: 1em;
  line-height: 1.62em;
  color: #454545;
}
.making__vantage-text b {
  font-weight: 500;
}
@media (max-width: 767px) {
  .making__vantage-text {
    padding-top: 0.7em;
    font-size: 1.1em;
    line-height: 1.49em;
  }
}
.making__edge {
  padding-left: 2.8em;
  width: 29.4%;
}
@media (max-width: 767px) {
  .making__edge {
    padding-left: 0;
    width: 100%;
    padding-top: 3.7em;
  }
}
.making__edge-item {
  background: #EDEDED;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 1em;
  text-align: center;
  padding: 1.5em 1em 2.1em 1em;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .making__edge-item {
    border-radius: 1.47em;
    padding: 2.4em 1em 3.15em 1em;
  }
}
.making__edge-item:before {
  content: "";
  position: absolute;
  top: 0.58em;
  right: 0.58em;
  bottom: 0.58em;
  left: 0.58em;
  background-color: #ffffff;
  border-radius: 1em;
  z-index: -1;
}
@media (max-width: 767px) {
  .making__edge-item:before {
    top: 0.95em;
    right: 0.95em;
    bottom: 0.95em;
    left: 0.95em;
    border-radius: 1.47em;
  }
}
.making__edge-item:not(:last-child) {
  margin-bottom: 3.26em;
}
@media (max-width: 767px) {
  .making__edge-item:not(:last-child) {
    margin-bottom: 2.34em;
  }
}
.making__edge-num {
  font-weight: 700;
  font-size: 2em;
  line-height: 140%;
  color: #F6B518;
}
@media (max-width: 767px) {
  .making__edge-num {
    font-size: 2.95em;
    line-height: 140%;
  }
}
.making__edge-text {
  font-weight: 300;
  font-size: 1em;
  line-height: 1.22em;
  color: #454545;
}
@media (max-width: 767px) {
  .making__edge-text {
    font-size: 1.47em;
    line-height: 1.25em;
  }
}
.making__ellipse {
  background: rgba(249, 249, 249, 0.6);
  border-radius: 50%;
  position: absolute;
  width: 85.125em;
  height: 43.1875em;
  z-index: 2;
}
@media (max-width: 767px) {
  .making__ellipse {
    display: none;
  }
}
.making__ellipse-1 {
  left: 3.125em;
  bottom: -25.68em;
  filter: blur(6em);
}
.making__ellipse-2 {
  left: 4.5625em;
  bottom: -35.2em;
  filter: blur(3em);
}
.making__ellipse-3 {
  left: 2.4375em;
  bottom: -35.8em;
  filter: blur(3em);
}
.making__leaf {
  display: none;
  position: absolute;
  z-index: 4;
  pointer-events: none;
}
@media (max-width: 767px) {
  .making__leaf {
    display: block;
  }
}
.making__leaf-1 {
  top: 3em;
  left: 0;
  width: 4.1em;
}
.making__leaf-2 {
  top: 19.5em;
  right: 0;
  width: 7.2em;
}
.making__leaf-3 {
  top: 42.5em;
  left: 0;
  width: 8.2em;
}
.making__leaf-4 {
  top: 63.5em;
  right: 0;
  width: 6.2em;
}
.making__leaf-5 {
  top: 74em;
  left: 1.8em;
  width: 9.4em;
}

.own {
  padding-top: 6.2em;
  padding-bottom: 7.2em;
}
@media (max-width: 767px) {
  .own {
    padding-top: 5.6em;
    padding-bottom: 5.65em;
  }
}
.own__title {
  text-align: center;
}
.own__titlesub {
  text-align: center;
  font-weight: 500;
  font-size: 1.28em;
  line-height: 2.16em;
  color: #9B9B9B;
  display: none;
}
@media (max-width: 767px) {
  .own__titlesub {
    display: block;
    padding-top: 0.7em;
  }
}
.own__dyad {
  padding-top: 2.8em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .own__dyad {
    padding-top: 2.4em;
    flex-wrap: wrap;
  }
}
.own__left {
  width: 51.75%;
}
@media (max-width: 767px) {
  .own__left {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}
.own__figure {
  position: relative;
  width: 36.4em;
  height: 36.4em;
  margin-left: -0.7em;
}
@media (max-width: 767px) {
  .own__figure {
    width: 34.5em;
    height: 34.5em;
    margin: 0 -6em;
  }
}
.own__circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 0.157em solid #F6B518;
  border-radius: 50%;
}
.own__circle-1 {
  width: 100%;
  height: 100%;
  opacity: 0.15;
}
.own__circle-2 {
  width: 86%;
  height: 86%;
  opacity: 0.3;
}
.own__circle-3 {
  width: 75%;
  height: 75%;
  opacity: 0.5;
}
.own__circle-4 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 22.65em;
  height: 22.65em;
  background: #F6B518;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .own__circle-4 {
    width: 21.65em;
    height: 21.65em;
  }
}
.own__leaf-1 {
  pointer-events: none;
  position: absolute;
  top: 2.6em;
  left: -8.7em;
  width: 26.2em;
}
@media (max-width: 767px) {
  .own__leaf-1 {
    transform: rotate(-27.9deg);
    top: 13.4em;
    left: 3.4em;
    width: 20.2em;
  }
}
.own__leaf-2 {
  pointer-events: none;
  position: absolute;
  top: 1em;
  right: -0.8em;
  z-index: 2;
  width: 12em;
}
@media (max-width: 767px) {
  .own__leaf-2 {
    display: none;
  }
}
.own__leaf-3 {
  display: none;
  position: absolute;
  top: -5em;
  right: 2.5em;
  width: 23em;
}
@media (max-width: 767px) {
  .own__leaf-3 {
    display: block;
  }
}
.own__image {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15.43em;
  pointer-events: none;
}
@media (max-width: 767px) {
  .own__image {
    width: 14.6em;
  }
}
.own__right {
  width: 48.25%;
  padding-top: 1.05em;
  padding-right: 5em;
}
@media (max-width: 767px) {
  .own__right {
    width: 100%;
    padding-right: 0;
    padding-top: 2.05em;
  }
}
.own__topic {
  font-weight: 600;
  font-size: 1.37em;
  line-height: 1.37em;
  color: #F6B518;
  padding-left: 2.38em;
}
@media (max-width: 767px) {
  .own__topic {
    display: none;
  }
}
.own__list {
  padding-top: 1.3em;
}
.own__list-item {
  position: relative;
  font-weight: 300;
  font-size: 1em;
  line-height: 1.617em;
  color: #454545;
  padding-left: 3.2em;
}
@media (max-width: 767px) {
  .own__list-item {
    font-size: 1.1em;
    line-height: 1.51em;
    padding-left: 3.5em;
  }
  .own__list-item br {
    display: none;
  }
}
.own__list-item b {
  font-weight: 500;
}
.own__list-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.75em;
  height: 1.75em;
  background-image: url(../img/icon/check.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
@media (max-width: 767px) {
  .own__list-item:before {
    width: 2.36em;
    height: 2.36em;
  }
}
.own__list-item:not(:last-child) {
  margin-bottom: 1em;
}
@media (max-width: 767px) {
  .own__list-item:not(:last-child) {
    margin-bottom: 2em;
  }
}
.own__point {
  position: absolute;
}
.own__point-1 {
  left: 12.7em;
  top: 7.7em;
}
@media (max-width: 767px) {
  .own__point-1 {
    left: 12em;
    top: 7.5em;
  }
}
.own__point-1 .own__point-content {
  right: -3.9em;
  top: 1.35em;
  width: 15.67em;
  padding: 1.25em 1em 1.4em 1.1em;
}
@media (max-width: 767px) {
  .own__point-1 .own__point-content {
    right: -7.32em;
    top: auto;
    bottom: 2em;
    padding: 1.15em 1em 1.35em 1.3em;
    width: 16.4em;
  }
}
.own__point-2 {
  left: 20.6em;
  top: 12.3em;
}
@media (max-width: 767px) {
  .own__point-2 {
    left: 19.4em;
    top: 11.8em;
  }
}
.own__point-2 .own__point-content {
  left: 0.25em;
  top: 1.55em;
  width: 14em;
  padding: 1.25em 1em 1.4em 1.2em;
}
@media (max-width: 767px) {
  .own__point-2 .own__point-content {
    left: -3.22em;
    top: 1.9em;
    width: 14.7em;
    padding: 1.15em 1em 1.35em 1.3em;
    padding: 1.15em 1em 1.35em 1.3em;
  }
}
.own__point-3 {
  left: 12.7em;
  top: 18em;
}
@media (max-width: 767px) {
  .own__point-3 {
    left: 12em;
    top: 17.2em;
  }
}
.own__point-3 .own__point-content {
  right: -2.9em;
  top: 1.6em;
  width: 14em;
  padding: 1.25em 1em 1.4em 1.1em;
}
@media (max-width: 767px) {
  .own__point-3 .own__point-content {
    left: auto;
    right: -3.82em;
    top: 2.15em;
    padding: 1.15em 1em 1.35em 1.3em;
    width: 14.8em;
  }
}
.own__point-4 {
  left: 20.6em;
  top: 22.7em;
}
@media (max-width: 767px) {
  .own__point-4 {
    left: 19.4em;
    top: 21.6em;
  }
}
.own__point-4 .own__point-content {
  left: 0.25em;
  top: 1.55em;
  width: 14em;
  padding: 1.25em 1em 1.4em 1.2em;
}
@media (max-width: 767px) {
  .own__point-4 .own__point-content {
    left: auto;
    right: -2.4em;
    top: 1.78em;
    width: 14.7em;
    padding: 1.15em 1em 1.35em 1.3em;
  }
}
.own__point-btn {
  width: 2.2em;
  height: 2.2em;
  background: #FFCF5B;
  box-shadow: 0px 12px 15px rgba(250, 200, 34, 0.4);
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
@media (max-width: 767px) {
  .own__point-btn {
    width: 2.78em;
    height: 2.78em;
  }
}
.own__point-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 53%;
  width: 0.07em;
  background-color: #ffffff;
}
.own__point-btn:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 53%;
  height: 0.07em;
  background-color: #ffffff;
}
.own__point-btn:hover + .own__point-content {
  opacity: 1;
}
.own__point-content {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  border-radius: 1em;
  transition: all 0.3s;
}
.own__point-name {
  font-weight: 600;
  font-size: 1em;
  line-height: 1.85em;
  color: #454545;
  padding-bottom: 0.15em;
}
@media (max-width: 767px) {
  .own__point-name {
    font-size: 1.1em;
    line-height: 1.65em;
  }
}
.own__point-text {
  font-weight: 300;
  font-size: 0.88em;
  line-height: 1.44em;
  color: #454545;
}
@media (max-width: 767px) {
  .own__point-text {
    font-size: 0.93em;
    line-height: 1.26em;
  }
}
.own__btn {
  padding-top: 2.6em;
  text-align: center;
}
.own__btn a {
  padding: 1.1em 1.7em 1em 1.7em;
}
@media (max-width: 767px) {
  .own__btn {
    display: none;
  }
}
.own__btn-mob {
  text-align: center;
  padding-top: 3.2em;
  padding-left: 0.7em;
  display: none;
}
@media (max-width: 767px) {
  .own__btn-mob {
    display: block;
  }
}
.own__btn-mob a {
  padding: 1.6em 2.7em 1.7em 2.7em;
}

.records {
  padding-top: 6.2em;
  padding-bottom: 6.5em;
  background: #F9F9F9;
}
@media (max-width: 767px) {
  .records {
    padding-top: 5.55em;
    padding-bottom: 4.9em;
  }
}
.records__wrapper {
  position: relative;
}
.records__leaf-1 {
  display: none;
  position: absolute;
  top: 27.8em;
  right: -1.5em;
  width: 17.5em;
  pointer-events: none;
}
@media (max-width: 767px) {
  .records__leaf-1 {
    display: block;
  }
}
.records__title {
  text-align: center;
}
.records__dyad {
  padding-top: 3.6em;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .records__dyad {
    flex-wrap: wrap;
  }
}
.records__left {
  width: 39.8%;
}
@media (max-width: 767px) {
  .records__left {
    width: 100%;
    order: 2;
    padding-top: 1.8em;
  }
}
.records__list {
  counter-reset: list;
}
.records__box {
  padding-top: 3.4em;
  padding-bottom: 0.9em;
  position: relative;
}
@media (max-width: 767px) {
  .records__box {
    padding-bottom: 0.6em;
  }
}
.records__box:before {
  counter-increment: list;
  content: "0" counter(list);
  position: absolute;
  left: 0.42em;
  top: 0;
  font-family: "Roboto Slab";
  font-size: 4.4em;
  line-height: 1.135em;
  background: linear-gradient(180deg, #DDDBDB 0%, rgba(231, 231, 231, 0.2) 84.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: rgba(196, 196, 196, 0.72);
}
@media (max-width: 767px) {
  .records__box:before {
    font-size: 4.6em;
  }
}
.records__item {
  background: #EDEDED;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  font-weight: 600;
  font-size: 1.492em;
  line-height: 1.25em;
  color: #F6B518;
  position: relative;
  z-index: 1;
  border-radius: 0.7em;
  padding: 1.25em 1.18em 1.25em 1.18em;
}
@media (max-width: 767px) {
  .records__item {
    padding: 1.65em 0.5em 1.8em 1.2em;
    font-size: 1.47em;
    line-height: 1.3em;
  }
}
.records__item:before {
  content: "";
  position: absolute;
  top: 0.38em;
  right: 0.38em;
  bottom: 0.38em;
  left: 0.38em;
  background-color: #ffffff;
  border-radius: 0.7em;
  z-index: -1;
}
.records__right {
  width: 50.5%;
  margin-right: 1.9em;
  padding-top: 0.9em;
}
@media (max-width: 767px) {
  .records__right {
    width: 100%;
    margin-right: 0;
    padding-top: 0;
    order: 1;
  }
}
.records__figure {
  padding-bottom: 100%;
  width: 100%;
  border-radius: 50%;
  background: #F6B518;
  position: relative;
}
.records__image {
  position: absolute;
  left: 50%;
  top: 47.8%;
  transform: translate(-50%, -50%);
  width: 107%;
  pointer-events: none;
}

.assortment {
  padding-top: 6.2em;
  padding-bottom: 7.3em;
}
@media (max-width: 767px) {
  .assortment {
    padding-top: 7.85em;
    padding-bottom: 5.4em;
  }
}
.assortment__title {
  text-align: center;
}
@media (max-width: 767px) {
  .assortment__title {
    display: none;
  }
}
.assortment__title-mob {
  display: none;
  text-align: center;
  font-weight: 700;
  font-size: 1.66em;
  line-height: 1.45em;
  color: #5A5A5A;
}
@media (max-width: 767px) {
  .assortment__title-mob {
    display: block;
  }
}
.assortment__titlesub-mob {
  padding-top: 1.02em;
  font-weight: 500;
  font-size: 1.29em;
  line-height: 1.5em;
  text-align: center;
  color: #9B9B9B;
  display: none;
}
@media (max-width: 767px) {
  .assortment__titlesub-mob {
    display: block;
  }
}
.assortment__mass {
  padding-top: 1.15em;
}
@media (max-width: 767px) {
  .assortment__mass {
    padding-top: 1.57em;
  }
}
.assortment__set {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -1.95em;
}
@media (max-width: 767px) {
  .assortment__set {
    flex-wrap: wrap;
  }
}
.assortment__box {
  width: 33.3%;
  padding-top: 2.87em;
  padding-left: 1.95em;
}
@media (max-width: 767px) {
  .assortment__box {
    width: 100%;
    padding-top: 2.35em;
  }
}
.assortment__item {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.6em;
  padding: 2.5em 2.2em 1.3em 2.2em;
  min-height: 24.31em;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 767px) {
  .assortment__item {
    text-align: center;
    padding: 3em 2em 1.6em 2em;
    min-height: 26.5em;
  }
}
.assortment__aloft {
  width: 100%;
  padding: 0 0.3em;
}
.assortment__name {
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.28em;
  color: #5A5A5A;
}
@media (max-width: 767px) {
  .assortment__name {
    font-size: 1.46em;
    line-height: 1.38em;
  }
  .assortment__name br {
    display: none;
  }
}
.assortment__text {
  padding-top: 0.7em;
  font-weight: 300;
  font-size: 0.87em;
  line-height: 150%;
  color: #5A5A5A;
}
@media (max-width: 767px) {
  .assortment__text {
    padding-top: 0.6em;
    font-size: 1.1em;
    line-height: 1.5em;
  }
  .assortment__text br {
    display: none;
  }
}
.assortment__image {
  height: 12.81em;
  pointer-events: none;
}
@media (max-width: 767px) {
  .assortment__image {
    height: 12.88em;
  }
}
.assortment__circle {
  position: absolute;
  bottom: -8em;
  left: 50%;
  transform: translateX(-50%);
  width: 27.24em;
  height: 21.7em;
  z-index: -1;
}
.assortment__btn {
  padding-top: 3.8em;
  text-align: center;
}
@media (max-width: 767px) {
  .assortment__btn {
    padding-top: 3.6em;
  }
}
.assortment__btn a {
  padding: 1em 1.8em 1.1em 1.8em;
}
@media (max-width: 767px) {
  .assortment__btn a {
    padding: 1.7em 1.1em 1.7em 1.1em;
  }
}

.boost {
  padding-top: 6.38em;
  padding-bottom: 10.7em;
  background: #F9F9F9;
}
@media (max-width: 767px) {
  .boost {
    padding-top: 5.55em;
    padding-bottom: 9.4em;
  }
}
.boost__wrapper {
  position: relative;
  z-index: 1;
}
.boost__circle {
  position: absolute;
  right: 15.65em;
  bottom: -8.1em;
  width: 83.6em;
  height: 45.32em;
  z-index: -1;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}
@media (max-width: 767px) {
  .boost__circle {
    display: none;
  }
}
.boost__leaf-1 {
  position: absolute;
  left: 25em;
  top: 4.3em;
  width: 10.2em;
  pointer-events: none;
}
@media (max-width: 767px) {
  .boost__leaf-1 {
    left: auto;
    right: -6.8em;
    top: 13em;
  }
}
.boost__leaf-2 {
  position: absolute;
  right: -4.4em;
  bottom: -7.9em;
  width: 21.4em;
  z-index: -1;
  pointer-events: none;
}
@media (max-width: 767px) {
  .boost__leaf-2 {
    right: auto;
    left: -2.5em;
    bottom: -15.4em;
    transform: scale(-1, 1) rotate(-9deg);
  }
}
.boost__title {
  text-align: center;
}
@media (max-width: 767px) {
  .boost__title {
    display: none;
  }
}
.boost__title-mob {
  display: none;
  font-weight: 700;
  font-size: 1.66em;
  line-height: 1.45em;
  text-align: center;
  color: #5A5A5A;
}
@media (max-width: 767px) {
  .boost__title-mob {
    display: block;
  }
}
.boost__titlesub {
  padding-top: 0.85em;
  font-weight: 500;
  font-size: 1.365em;
  line-height: 1.455em;
  text-align: center;
  color: rgba(54, 54, 54, 0.5);
}
@media (max-width: 767px) {
  .boost__titlesub {
    display: none;
  }
}
.boost__dyad {
  padding-top: 5.8em;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .boost__dyad {
    flex-wrap: wrap;
    padding-top: 1.95em;
  }
}
.boost__left {
  width: 43.4%;
}
@media (max-width: 767px) {
  .boost__left {
    width: 100%;
  }
}
.boost__topic {
  padding-left: 1.6em;
  padding-bottom: 1.2em;
  font-weight: 700;
  font-size: 1.24em;
  line-height: 1.4em;
  color: #5A5A5A;
}
@media (max-width: 767px) {
  .boost__topic {
    padding-left: 0;
    text-align: center;
    font-size: 1.27em;
    line-height: 1.28em;
    margin: 0 -1.4em;
    padding-bottom: 1.06em;
  }
}
.boost__list {
  background: #EDEDED;
  box-shadow: 0px 45px 50px rgba(0, 0, 0, 0.05);
  border-radius: 1em;
  padding: 0.7em;
}
@media (max-width: 767px) {
  .boost__list {
    margin: 0 -1.4em;
    padding: 0.28em 0;
    border-radius: 0;
  }
}
.boost__point {
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.7em 1em 0.71em 1.8em;
  font-weight: 500;
  font-size: 1.118em;
  line-height: 1.32em;
  color: #5A5A5A;
  margin-bottom: 0.2em;
}
@media (max-width: 767px) {
  .boost__point {
    margin-bottom: 0.28em;
    font-size: 1.195em;
    line-height: 1.39em;
    padding: 0.65em 1em 0.7em 1.2em;
  }
}
.boost__point:before {
  content: "";
  display: block;
  min-width: 2.24em;
  max-width: 2.24em;
  height: 2.24em;
  border-radius: 50%;
  background-image: url(../img/icon/check.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 1.6em;
}
@media (max-width: 767px) {
  .boost__point:before {
    min-width: 2.32em;
    max-width: 2.32em;
    height: 2.32em;
  }
}
.boost__point:first-child {
  border-radius: 1em 1em 0 0;
}
@media (max-width: 767px) {
  .boost__point:first-child {
    border-radius: 0;
  }
}
.boost__point:last-child {
  border-radius: 0 0 1em 1em;
}
@media (max-width: 767px) {
  .boost__point:last-child {
    border-radius: 0;
  }
}
.boost__right {
  width: 48.1%;
  padding-top: 0.2em;
  padding-right: 4em;
}
@media (max-width: 767px) {
  .boost__right {
    width: 100%;
    padding-right: 0;
    padding-top: 0;
  }
}
.boost__text {
  font-weight: 300;
  font-size: 0.995em;
  line-height: 1.6em;
  color: #454545;
}
@media (max-width: 767px) {
  .boost__text {
    display: none;
  }
}
.boost__text-mob {
  display: none;
  padding-top: 2.1em;
  font-weight: 300;
  font-size: 1.1em;
  line-height: 1.5em;
  color: #454545;
}
.boost__text-mob p:not(:last-child) {
  padding-bottom: 0.8em;
}
@media (max-width: 767px) {
  .boost__text-mob {
    display: block;
  }
}
.boost__figure {
  width: 26.74em;
  height: 26.74em;
  border-radius: 50%;
  background: #F6B518;
  margin-top: 4.5em;
  margin-left: 1.7em;
  position: relative;
}
@media (max-width: 767px) {
  .boost__figure {
    width: 20em;
    height: 20em;
    margin-top: 3.15em;
    margin-left: auto;
    margin-right: -0.7em;
  }
}
.boost__image {
  position: absolute;
  left: 41%;
  top: 52.8%;
  transform: translate(-50%, -50%);
  width: 177%;
  pointer-events: none;
}
@media (max-width: 767px) {
  .boost__image {
    top: 49.8%;
  }
}

.segments {
  padding-top: 6.8em;
  padding-bottom: 7.4em;
}
@media (max-width: 767px) {
  .segments {
    padding-top: 8em;
    padding-bottom: 5.5em;
  }
}
.segments__title {
  text-align: center;
}
@media (max-width: 767px) {
  .segments__title {
    display: none;
  }
}
.segments__title-mob {
  margin: 0 -0.2em;
  display: none;
  font-weight: 700;
  font-size: 1.65em;
  line-height: 1.42em;
  color: #5A5A5A;
  text-align: center;
}
@media (max-width: 767px) {
  .segments__title-mob {
    display: block;
  }
}
.segments__mass {
  padding-top: 3.4em;
}
@media (max-width: 767px) {
  .segments__mass {
    padding-top: 1.5em;
  }
}
.segments__set {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -1.88em;
  counter-reset: list;
}
.segments__box {
  padding-left: 1.88em;
  padding-top: 1.88em;
  width: 25%;
}
@media (min-width: 768px) {
  .segments__box:nth-child(8n+1) .segments__item, .segments__box:nth-child(8n+3) .segments__item, .segments__box:nth-child(8n+6) .segments__item, .segments__box:nth-child(8n+8) .segments__item {
    background: #F6B518;
    color: #ffffff;
  }
  .segments__box:nth-child(8n+1) .segments__item:after, .segments__box:nth-child(8n+3) .segments__item:after, .segments__box:nth-child(8n+6) .segments__item:after, .segments__box:nth-child(8n+8) .segments__item:after {
    border: 0.05em solid #ffffff;
  }
  .segments__box:nth-child(8n+1) .segments__item:before, .segments__box:nth-child(8n+3) .segments__item:before, .segments__box:nth-child(8n+6) .segments__item:before, .segments__box:nth-child(8n+8) .segments__item:before {
    background: linear-gradient(180.68deg, #FFECBF 0.59%, rgba(255, 236, 191, 0) 86.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFECBF;
    opacity: 0.4;
  }
}
@media (max-width: 767px) {
  .segments__box {
    width: 100%;
    padding-top: 2.3em;
  }
  .segments__box:nth-child(odd) .segments__item {
    background: #F6B518;
    color: #ffffff;
  }
  .segments__box:nth-child(odd) .segments__item:after {
    border: 0.05em solid #ffffff;
  }
  .segments__box:nth-child(odd) .segments__item:before {
    background: linear-gradient(180.68deg, #FFECBF 0.59%, rgba(255, 236, 191, 0) 86.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFECBF;
    opacity: 0.4;
  }
}
.segments__item {
  background: #FFF0C2;
  border-radius: 0.6em;
  min-height: 10.86em;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
  font-weight: 600;
  font-size: 1.118em;
  line-height: 1.44em;
  text-align: center;
  color: #9E9E9E;
  padding: 4.7em 1.1em 1.1em 1.1em;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .segments__item {
    font-size: 1.64em;
    line-height: 1.5em;
    min-height: 9.19em;
  }
}
.segments__item:after {
  content: "";
  position: absolute;
  top: 0.56em;
  right: 0.56em;
  bottom: 0.56em;
  left: 0.56em;
  border-radius: 0.6em;
  border: 0.05em solid #CCCCCC;
  z-index: -1;
}
.segments__item:before {
  counter-increment: list;
  content: "0" counter(list);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0.5em;
  font-family: "Roboto Slab";
  font-size: 3.892em;
  line-height: 1.143em;
  background: linear-gradient(180.55deg, #DDDDDD 0.47%, rgba(181, 181, 181, 0) 82.09%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #DDDDDD;
  z-index: -1;
  opacity: 0.3;
}
@media (max-width: 767px) {
  .segments__item:before {
    top: 0.2em;
  }
}

.proposal {
  background: #F9F9F9;
  padding-top: 6.2em;
  padding-bottom: 2.5em;
}
@media (max-width: 767px) {
  .proposal {
    padding-top: 5.6em;
    padding-bottom: 5.6em;
  }
}
.proposal__wrapper {
  position: relative;
  z-index: 1;
}
.proposal__leaf-1 {
  position: absolute;
  bottom: -2.5em;
  left: -6.1em;
  width: 56.8em;
  pointer-events: none;
}
@media (max-width: 767px) {
  .proposal__leaf-1 {
    display: none;
  }
}
.proposal__leaf-2 {
  position: absolute;
  left: -1.5em;
  top: 0.2em;
  width: 10em;
  display: none;
  pointer-events: none;
}
@media (max-width: 767px) {
  .proposal__leaf-2 {
    display: block;
  }
}
.proposal__leaf-3 {
  position: absolute;
  right: -1.5em;
  bottom: -5.5em;
  display: none;
  pointer-events: none;
  width: 10em;
}
@media (max-width: 767px) {
  .proposal__leaf-3 {
    display: block;
  }
}
.proposal__title {
  text-align: center;
}
.proposal__text {
  padding-top: 1.3em;
  text-align: center;
  font-weight: 500;
  font-size: 1.373em;
  line-height: 1.67em;
  color: rgba(54, 54, 54, 0.5);
}
.proposal__text span {
  color: #F6B518;
}
@media (max-width: 767px) {
  .proposal__text {
    display: none;
  }
}
.proposal__dyad {
  padding-top: 1.5em;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .proposal__dyad {
    padding-top: 2.75em;
  }
}
.proposal__left {
  width: 52.2%;
}
@media (max-width: 767px) {
  .proposal__left {
    display: none;
  }
}
.proposal__figure {
  width: 100%;
  position: relative;
}
.proposal__image {
  width: 100%;
  pointer-events: none;
}
.proposal__discount {
  position: absolute;
  right: 0.1em;
  top: 3.2em;
  width: 10.9em;
  height: 10.9em;
  object-fit: contain;
  pointer-events: none;
}
.proposal__right {
  width: 44.8%;
  padding-top: 3.2em;
  padding-right: 0.3em;
}
@media (max-width: 767px) {
  .proposal__right {
    width: 100%;
    padding-right: 0;
    padding-top: 0;
  }
}
.proposal__form {
  background: #EDEDED;
  box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.05);
  border-radius: 1em;
  padding: 3.6em 4.05em 3.3em 4.05em;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .proposal__form {
    border-radius: 0.8em;
    padding: 3.5em 1.5em 3em 1.5em;
  }
}
.proposal__form:before {
  content: "";
  position: absolute;
  top: 0.7em;
  right: 0.7em;
  bottom: 0.7em;
  left: 0.7em;
  background-color: #ffffff;
  border-radius: 1em;
  z-index: -1;
}
@media (max-width: 767px) {
  .proposal__form:before {
    top: 0.6em;
    right: 0.6em;
    bottom: 0.6em;
    left: 0.6em;
    border-radius: 0.8em;
  }
}
.proposal__topic {
  font-weight: 600;
  font-size: 1.5em;
  line-height: 1.46em;
  text-align: center;
  text-transform: uppercase;
  color: #5A5A5A;
}
@media (max-width: 767px) {
  .proposal__topic {
    display: none;
  }
}
.proposal__topic-mob {
  display: none;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 1.7em;
  text-align: center;
  text-transform: uppercase;
  color: #5A5A5A;
}
.proposal__topic-mob b {
  color: #F6B518;
}
@media (max-width: 767px) {
  .proposal__topic-mob {
    display: block;
  }
}
.proposal__set {
  padding-top: 2em;
  max-width: 83.6%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .proposal__set {
    padding-top: 1.65em;
    max-width: 87.6%;
  }
}
.proposal__row {
  padding-bottom: 0.77em;
}
@media (max-width: 767px) {
  .proposal__row {
    padding-bottom: 0.67em;
  }
}
.proposal__icon {
  position: absolute;
  left: 1.2em;
  top: 50%;
  transform: translateY(-50%);
  width: 2.06em;
  height: 2.06em;
  border-radius: 50%;
  pointer-events: none;
}
@media (max-width: 767px) {
  .proposal__icon {
    width: 1.74em;
    height: 1.74em;
    left: 1em;
  }
}
.proposal__field {
  position: relative;
}
.proposal__field input {
  display: block;
  width: 100%;
  background: #F9F9F9;
  border-radius: 0.5em;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 0.87em;
  line-height: 1.43em;
  color: #5A5A5A;
  border: none;
  padding: 1.9em 1em 1.9em 4.8em;
  transition: all 0.3s;
}
.proposal__field input::placeholder {
  color: #AFAFAF;
}
.proposal__field input.invalid {
  box-shadow: inset 0px 2px 6px rgba(255, 0, 0, 0.4);
}
@media (max-width: 767px) {
  .proposal__field input {
    font-size: 0.745em;
    line-height: 1.3em;
    padding: 1.98em 1em 1.9em 4.7em;
  }
}
.proposal__note {
  padding-top: 0.95em;
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 0.87em;
  line-height: 1.43em;
  text-align: center;
  color: #A0A0A0;
}
@media (max-width: 767px) {
  .proposal__note {
    font-size: 0.746em;
    line-height: 1.32em;
    padding-top: 1.1em;
  }
}
.proposal__btn {
  padding-top: 1.4em;
  text-align: center;
}
@media (max-width: 767px) {
  .proposal__btn {
    padding-top: 1.2em;
  }
}
.proposal__btn button {
  padding: 1.08em 1.5em 1em 1.5em;
}
@media (max-width: 767px) {
  .proposal__btn button {
    padding: 0.9em 1.2em 1em 1.2em;
  }
}
.proposal__consent {
  padding-top: 1.35em;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.25em;
  text-align: center;
  color: #C6C2C2;
}
@media (max-width: 767px) {
  .proposal__consent {
    font-size: 0.82em;
    line-height: 1.45em;
    padding-top: 1em;
  }
}
.proposal__consent a {
  font-weight: 600;
  color: #C6C2C2;
  transition: all 0.3s;
}
.proposal__consent a:hover {
  opacity: 0.5;
}

.hat {
  background: #F9F9F9;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
}
@media (max-width: 767px) {
  .hat {
    padding-top: 1.35em;
    padding-bottom: 1.35em;
  }
}
.hat__container {
  max-width: 67em;
  width: 100%;
  margin: 0 auto;
  padding: 0 0.95em;
}
@media (max-width: 991px) {
  .hat__container {
    padding: 0 1.4em;
    max-width: 74em;
  }
}
@media (min-width: 1920px) {
  .hat__container {
    max-width: 63.8em;
    padding: 0 0.7em;
  }
}
.hat__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 4;
}
.hat__left {
  padding-left: 1.3em;
  width: 52%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .hat__left {
    width: 100%;
    justify-content: center;
    padding-left: 0;
  }
}
.hat__logotype {
  display: inline-flex;
}
.hat__logotype-link {
  display: inline-flex;
}
.hat__logotype-link img {
  width: 10.26em;
}
@media (max-width: 767px) {
  .hat__logotype-link img {
    width: 11.15em;
  }
}
.hat__company {
  max-width: 26em;
  padding-left: 2.8em;
  font-size: 0.75em;
  line-height: 1.3em;
  color: #5A5A5A;
  position: relative;
}
@media (max-width: 767px) {
  .hat__company {
    display: none;
  }
}
.hat__right {
  padding-top: 0.7em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 48%;
}
@media (max-width: 767px) {
  .hat__right {
    display: none;
  }
}
.hat__network {
  text-align: right;
  padding-right: 3.5em;
}
.hat__network-title {
  font-size: 0.625em;
  line-height: 1.2em;
  color: #5A5A5A;
}
.hat__network-set {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.hat__network-box {
  display: inline-flex;
  padding: 0.5em 0 0 0.73em;
}
.hat__network-link {
  width: 1.7em;
  height: 1.7em;
  border-radius: 50%;
  overflow: hidden;
  transition: all 0.3s;
}
.hat__network-link img {
  width: 100%;
  height: 100%;
}
.hat__network-link:hover {
  opacity: 0.5;
}
.hat__phone {
  text-align: right;
}
.hat__phone-title {
  font-size: 0.625em;
  line-height: 1.2em;
  color: #5A5A5A;
  padding-bottom: 0.5em;
}
.hat__phone-link a {
  font-weight: 500;
  font-size: 1.25em;
  line-height: 1.25em;
  color: #5A5A5A;
  text-decoration: none;
  transition: all 0.3s;
}
.hat__phone-link a:hover {
  color: #F6B518;
}

.thanks {
  background: #F9F9F9;
  padding-top: 4.3vh;
  padding-bottom: 4vh;
  position: relative;
  z-index: 2;
}
@media (max-width: 767px) {
  .thanks {
    padding-top: 1.27vh;
  }
}
.thanks__title {
  font-weight: 600;
  font-size: 1.49em;
  line-height: 130%;
  text-align: center;
  color: #5A5A5A;
}
.thanks__title br {
  display: none;
}
@media (max-width: 767px) {
  .thanks__title {
    font-size: 1.47em;
    line-height: 130%;
  }
  .thanks__title br {
    display: block;
  }
  .thanks__title p:not(:last-child) {
    padding-bottom: 0.4em;
  }
}
.thanks__mail {
  padding-top: 3.3vh;
}
@media (max-width: 767px) {
  .thanks__mail {
    padding-top: 3.6vh;
  }
}
.thanks__mail-topic {
  font-size: 1.247em;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
  color: #9B9B9B;
  padding-bottom: 2.2vh;
}
@media (max-width: 767px) {
  .thanks__mail-topic {
    font-size: 1.29em;
    padding-bottom: 3.2vh;
  }
}
.thanks__mail-mass {
  max-width: 33.2em;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .thanks__mail-mass {
    margin-left: 0;
    max-width: 32.1em;
  }
}
.thanks__mail-set {
  margin-left: -1.56em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .thanks__mail-set {
    margin-left: -1.38em;
    justify-content: center;
  }
}
.thanks__mail-box {
  max-width: 25%;
  padding-left: 1.56em;
  padding-bottom: 1.3em;
}
@media (max-width: 767px) {
  .thanks__mail-box {
    padding-left: 1.38em;
    padding-bottom: 0.7em;
    text-align: center;
    max-width: none;
    width: auto;
  }
}
.thanks__mail-box a {
  display: inline-flex;
  width: 100%;
  transition: all 0.3s;
}
.thanks__mail-box a:hover {
  opacity: 0.5;
}
.thanks__mail-box img {
  max-width: 7.2em;
  max-height: 2.8em;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .thanks__mail-box img {
    max-width: 9.58em;
    max-height: 3.8em;
  }
}
.thanks__price {
  padding-top: 3.2vh;
}
.thanks__price-topic {
  font-weight: 600;
  font-size: 1.27em;
  line-height: 120%;
  text-align: center;
  color: #5A5A5A;
  padding-bottom: 2.8vh;
}
@media (max-width: 767px) {
  .thanks__price-topic {
    font-size: 1.1em;
    line-height: 140%;
    padding-bottom: 1.8vh;
  }
}
.thanks__price-link {
  text-align: center;
}
.thanks__price-link a {
  padding: 1em 1.5em 1.1em 1.5em;
}
@media (max-width: 767px) {
  .thanks__price-link a {
    padding: 1.5em 2.4em 1.8em 2.4em;
  }
}
.thanks__text {
  padding-top: 2.2vh;
  max-width: 32.8em;
  margin: 0 auto;
  font-size: 0.875em;
  line-height: 130%;
  text-align: center;
  color: #9B9B9B;
}
@media (max-width: 767px) {
  .thanks__text {
    padding-top: 1.6vh;
    font-size: 0.92em;
    line-height: 150%;
    padding-right: 0;
    max-width: 90%;
  }
}
.thanks__segue {
  max-width: 50em;
  margin: 0 auto;
  padding-top: 4.8vh;
}
@media (max-width: 767px) {
  .thanks__segue {
    padding-top: 3.1vh;
  }
}
.thanks__segue-topic {
  font-weight: 500;
  font-size: 1.27em;
  line-height: 130%;
  text-align: center;
  color: #5A5A5A;
}
@media (max-width: 767px) {
  .thanks__segue-topic {
    font-size: 1.12em;
    line-height: 140%;
  }
}
.thanks__segue-link {
  padding-top: 2.7vh;
  text-align: center;
}
@media (max-width: 767px) {
  .thanks__segue-link {
    padding-top: 1.7vh;
  }
}
.thanks__segue-link a {
  padding: 1em 2.7em 1.1em 2.7em;
}
@media (max-width: 767px) {
  .thanks__segue-link a {
    padding: 1.5em 3.6em 1.8em 3.6em;
  }
}