.footer{
	position: relative;
	padding-top: 2.25em;
	padding-bottom: 2.7em;
	@media (max-width: $max-sm) {
		padding-top: 2.6em;
		padding-bottom: 2.2em;
	}
	&__wrapper{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		@media (max-width: $max-sm) {
			flex-wrap: wrap;
		}
	}
	&__left{
		width: 27%;
		padding-top: 0.5em;
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__logotype{
		display: inline-flex;
		&-link{
			display: inline-flex;
			img{
				width: 12.06em;
			}
		}
	}
	&__company{
		padding-top: 1.1em;
		font-size: 0.87em;
		line-height: 1.15em;
		color: rgba(0, 0, 0, 0.5);
	}
	&__center{
		width: 27%;
		@media (max-width: $max-sm) {
			width: 100%;
		}
	}
	&__data{
		font-weight: 300;
		font-size: 0.87em;
		line-height: 150%;
		color: #6F6F6F;
		@media (max-width: $max-sm) {
			text-align: center;
			font-size: 1.1em;
			line-height: 1.52em;
		}
	}
	&__politics{
		padding-top: 0.8em;
		@media (max-width: $max-sm) {
			text-align: center;
		}
		a{
			font-weight: 500;
			display: inline-block;
			font-size: 0.87em;
			line-height: 150%;
			color: #383838;
			@include trs;
			&:hover{
				color: #F6B518;
			}
			@media (max-width: $max-sm) {
				font-size: 1.1em;
			}
		}
	}
	&__right{
		width: 31.6%;
		padding-right: 2em;
		@media (max-width: $max-sm) {
			width: 100%;
			padding-right: 0;
		}
	}
	&__warning{
		font-weight: 300;
		font-size: 0.87em;
		line-height: 150%;
		color: #6F6F6F;
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	&__network{
		&-set{
			display: flex;
			align-items: flex-end;
			justify-content: flex-start;
			flex-wrap: wrap;
			@media (max-width: $max-sm) {
				justify-content: center;
			}
		}
		&-box{
			display: inline-flex;
			padding: 1.4em 0.98em 0 0;
			@media (max-width: $max-sm) {
				padding: 1em 0.4em 0 0.4em;
			}
		}
		&-link{
			display: inline-flex;
			justify-content: center;
			@include trs;
			&:hover{
				opacity: 0.8;
			}
			img{
				max-width: 3.45em;
				max-height: 1.99em;
				@media (max-width: $max-sm) {
					max-width: 3.81em;
					max-height: 2.1em;
				}
			}
		}
	}
}